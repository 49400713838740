<template>
	<call-draggable :data="call" type="MyCallsCall" :draggable="true" :target="false">
		<div v-if="call" class="call-box" @contextmenu="showContextMenu" @dblclick="dblClick">
			<div class="state-icon" :style="{'background':stateColor}">
				<glyph-icon :icon="stateIcon" size="25" color="#fff" :class="{'onhold':call.state=='CALL_HOLD','ringing':call.state=='CALL_RING'}"></glyph-icon>
			</div>
			<div class="labels">
				<div v-if="call.leg=='I'&&call.callee.name" class="name">{{ call.callee.name }}</div>
				<div v-if="call.leg=='I'&&!call.callee.name" class="name">{{ call.callee.number | cutNationalPrefix }}</div>
				<div v-if="call.leg=='O'&&!call.caller.privacy&&call.caller.name" class="name">{{ call.caller.name }}</div>
				<div v-if="call.leg=='O'&&!call.caller.privacy&&!call.caller.name" class="name">{{ call.caller.number | cutNationalPrefix }}</div>
				<div v-if="call.leg=='O'&&call.caller.privacy" class="name">{{ $t('mycalls.hiddenNumber') }}</div>
				<div class="number">
					<div v-if="call.leg=='O'&&call.caller.queueId"><glyph-icon icon="44-user-group" color="var(--label-queue-color)" size="13"></glyph-icon></div>
					<div v-if="call.leg=='O'&&call.caller.queueId" class="queue">{{ call.caller.queueName }}&nbsp;</div>
					<div v-if="call.leg=='I'&&call.callee.name" class="label">{{ call.callee.number | cutNationalPrefix }}</div>
					<div v-if="call.leg=='O'&&!call.caller.privacy&&call.caller.name" class="label">{{ call.caller.number | cutNationalPrefix }}</div>
					<country-flag v-if="countryFlagName" :icon="countryFlagName" size="13" class="country-flag"></country-flag>
				</div>
				<div v-if="variableValue" class="variable"><span>{{ variableName }}:</span> {{ variableValue }}</div>
			</div>
			<div class="duration-box" :class="{'visible':call.state!='CALL_RING'}">
				<div>{{ currentCallDuration | duration(true) }}</div>
			</div>
		</div>
		<div v-if="!last" class="bottom-line"></div>
		<v-contextmenu :ref="call._id">
			<v-contextmenu-item v-if="call.state=='CALL_RING'&&(phoneType=='SIPPHONE'||phoneType=='SOFTPHONE')" @click="answer">{{ $t('mycalls.action.answer') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="call.state=='CALL_ANSWER'&&(phoneType=='SIPPHONE'||phoneType=='SOFTPHONE')" @click="hold">{{ $t('mycalls.action.hold') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="call.state=='CALL_HOLD'&&call.holdOwner&&(phoneType=='SIPPHONE'||phoneType=='SOFTPHONE')" @click="unhold">{{ $t('mycalls.action.resume') }}</v-contextmenu-item>
			<v-contextmenu-item @click="hangup" v-if="call.state=='CALL_RING'">{{ $t('mycalls.action.reject') }}</v-contextmenu-item>
			<v-contextmenu-item @click="hangup" v-if="call.state!='CALL_RING'">{{ $t('mycalls.action.hangup') }}</v-contextmenu-item>
			<v-contextmenu-submenu v-if="callsICanTransferTo.length>0" :title="$t('mycalls.action.transferTo')">
				<v-contextmenu-item v-for="(other,otherindex) in callsICanTransferTo" v-bind:key="otherindex" @click="transferTo(other)">
					<span v-if="other.leg=='I'&&other.callee.name">{{ other.callee.name }}</span>
					<span v-if="other.leg=='I'&&!other.callee.name">{{ other.callee.number | cutNationalPrefix }}</span>
					<span v-if="other.leg=='O'&&!other.caller.privacy&&other.caller.name">{{ other.caller.name }}</span>
					<span v-if="other.leg=='O'&&!other.caller.privacy&&!other.caller.name">{{ other.caller.number | cutNationalPrefix }}</span>
					<span v-if="other.leg=='O'&&other.caller.privacy">{{ $t('mycalls.hiddenNumber') }}</span>
				</v-contextmenu-item>
			</v-contextmenu-submenu>
			<v-contextmenu-item divider></v-contextmenu-item>
			<v-contextmenu-item @click="recordingToggle" v-if="canRecord&&call.state=='CALL_ANSWER'">{{ isRecording ? $t('mycalls.action.stopRecording') : $t('mycalls.action.startRecording') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="variableValue" @click="copyVariableToClipboard">{{ $t('mycalls.action.copyVariableToClipboard').replace('%%name%%', variableName.toLowerCase()) }}</v-contextmenu-item>
			<v-contextmenu-item v-if="this.call.leg=='I'" @click="copyNumberToClickboard(call.callee.number)">{{ $t('mycalls.action.copyNumberToClipboard') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="this.call.leg=='O'&&!call.caller.privacy" @click="copyNumberToClickboard(call.caller.number)">{{ $t('mycalls.action.copyNumberToClipboard') }}</v-contextmenu-item>
		</v-contextmenu>
	</call-draggable>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';
import CountryFlag from '../CountryFlag.vue';
import s from '../../settings';
import u from '../../utils/utils';
import employees from '../../data/employees';
import EventBus from '../../data/EventBus';
import MyNotification from '../../utils/MyNotification';
import CallDraggable from './CallDraggable';
import recordingState from '../../data/recordingState';
import BindCache from '../../utils/BindCache';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';
import logger from '../../data/logger';

export default {
	props: {
		call: Object,
		index: Number,
		variable: String,
		variableName: String,
		autoAnswer: Object,
		last: Boolean,
		phoneType: String,
		calls: Array
	},
	components: {
		GlyphIcon,
		CountryFlag,
		CallDraggable
	},
	data() {
		return {
			$t: i18n.t,
			currentCallDuration: 0,
			variableValue: null,
			timer2: null,
			s: s,
			autoAnswerPerformed: false,
			bindCache: new BindCache(this)
		};
	},
	computed: {
		callsICanTransferTo: function() {
			return this.calls.filter(o => o._id != this.call._id && (o.state == 'CALL_ANSWER' || o.state == 'CALL_HOLD'));
		},
		isRecording: function() {
			return recordingState.isRecording && recordingState.recordingIds.includes(this.call.channel);
		},
		canRecord: function() {
			return s.isAuthenticated && s.auth.recordingMode == 'DEMAND';
		},
		countryFlagName: function() {
			if (!this.call || (this.call.leg == 'O' && this.call.caller.privacy)) { return ''; }
			let number = (this.call.leg == 'I' ? this.call.callee.number : this.call.caller.number).substr(0, 6);
			let iso;
			while (!iso && number) {
				number = number.slice(0, -1);
				iso = u.ISO3166.prefix[number];
			}
			if (iso) { iso = iso.toLowerCase(); }
			if (number == s.pbxSettings.nationalPrefix) { iso = null; }
			return iso;
		},
		stateIcon: function() {
			let icon = '';
			if (this.call) {
				if (this.call.state == 'CALL_HOLD') { icon = '756-call-pause'; }
				else if (this.call.leg == 'I') {
					if (this.call.state == 'CALL_START') { icon = 'call-dialing'; }
					else if (this.call.state == 'CALL_ANSWER') { icon = 'call-outgoing'; }
				} else {
					if (this.call.state == 'CALL_START' || this.call.state == 'CALL_RING') { icon = 'call-ringing'; }
					else if (this.call.state == 'CALL_ANSWER') { icon = 'call-incoming'; }
				}
			}
			return icon;
		},
		stateColor: function() {
			if (!this.call) { return '#fff'; }
			switch (this.call.state) {
				case 'CALL_ANSWER': return 'var(--call-answered)';
				case 'CALL_HOLD': return 'var(--call-hold)';
				case 'CALL_RING': return 'var(--call-ringing)';
				case 'CALL_START':
					if (this.call.leg == 'I') {
						return 'var(--call-start)';
					} else {
						return 'var(--call-ringing)';
					}
				default:
					return 'var(--call-start)';
			}
		}
	},
	methods: {
		transferTo(other) {
			// Attended transfer
			//console.log('Omstil aktivt kald med ' + (this.call.leg == 'I' ? this.call.callee.number : this.call.caller.number) + ' til ' + (other.leg == 'I' ? other.callee.number : other.caller.number));
			EventBus.$emit('AttendedTransferRequested', { source: this.call._id, destination: other._id });
			s.http.get(`/pbx/app/action/transfer?source=${this.call._id}&destination=${other._id}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.transferFailedHeader'), message: i18n.t('common.error.transferFailedMessage') });
			});
		},
		dblClick() {
			if (this.call.state == 'CALL_HOLD' && (this.phoneType == 'SIPPHONE' || this.phoneType == 'SOFTPHONE')) { // I want to resume an on-hold call
				if (this.call.holdOwner) {
					this.unhold();
				} else {
					this.hangup();
				}
			} else if (this.call.state == 'CALL_RING' && (this.phoneType == 'SIPPHONE' || this.phoneType == 'SOFTPHONE')) { // I want to answer an incoming call
				this.answer();
			} else if (this.call.state == 'CALL_START') { // I am calling out (is ringing) but have given up and want to end the call
				if (this.phoneType == 'SIPPHONE') { this.hangup(); }
				if (this.phoneType == 'SOFTPHONE') { this.hangup(); }
			} else if (this.call.state == 'CALL_ANSWER' && (new Date().getTime() - this.call.answered.getTime()) > 3000) { // I have finished talking and want to end the call - but not within the first 3 seconds
				this.hangup();
			}
		},
		async recordingToggle() {
			const me = await employees.getMe();
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
			s.http.get('/pbx/recording/ondemand?extension=' + me.primaryExtension._id + '&mode=TOGGLE')
				.catch(err => {
					if (err.status == 403) {
						EventBus.$emit('CommomErrorModal', { header: i18n.t('mycalls.recordingHeader'), message: i18n.t('mycalls.recordingNotAllowed') });
					} else if (err.status == '404') {
						EventBus.$emit('CommomErrorModal', { header: i18n.t('mycalls.recordingHeader'), message: i18n.t('mycalls.recordingNoCall') });
					}
				});
		},
		showContextMenu(e) {
			e.preventDefault();
			setTimeout(() => { this.$refs[this.call._id].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		copyVariableToClipboard() {
			navigator.clipboard.writeText(this.variableValue);
		},
		copyNumberToClickboard(number) {
			navigator.clipboard.writeText(number.replace(s.pbxSettings.nationalPrefix, ''));
		},
		refreshDuration() {
			if (this.call) {

				// If another call was autoanswered within the last 2 seconds, and this is a call from a queue - hang it up
				// (done to handle the pick first in queue and second in queue arrives before pickup is completed)
				if (this.call.state == 'CALL_RING' && this.autoAnswer.last && (new Date() - this.autoAnswer.last) < 2000 && !this.autoAnswerPerformed) {
					if (this.call.caller.queueId) {
						this.hangup();
					}
				}

				if (this.call.leg == 'I') {
					this.currentCallDuration = Math.round((new Date() - this.call.started)/1000);
				} else if (this.call.answered) {
					this.currentCallDuration = Math.round((new Date() - this.call.answered)/1000);
				}

			} else {
				this.currentCallDuration = 0;
			}
		},
		autoAnswerCheck() {
			if (this.call.state == 'CALL_RING' && this.autoAnswer.enabled && (this.call.sessionId == this.autoAnswer.sessionId || `${this.call.pbx}:${this.call.callerChannelId}` == this.autoAnswer.sessionId)) {
				this.autoAnswer.enabled = false;
				this.autoAnswer.sessionId = null;
				this.autoAnswer.last = new Date();
				if (this.timer2) { clearInterval(this.timer2); this.timer2 = null; }
				this.autoAnswerPerformed = true;
				this.answer();
			}
		},
		async answer() {
			if (!(this.call.leg == 'O' && (this.call.state == 'CALL_RING' || this.call.state == 'CALL_START'))) {
				return; // Cannot answer call that is not in ringing state
			}
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
				if (session) {
					// Tell all calls not either this call, or a call already on hold, or a ringing call (cannot put a ringing call on hold) to all go on hold
					const sessionsNotOnHoldOrRinging = sipClient.getSessions().filter(o => o != session && !['CALL_HOLD', 'CALL_RING'].includes(o.data.tcxState));

					for (const session of sessionsNotOnHoldOrRinging) {
						EventBus.$emit('Call:ActivateHold', { sessionId: session._id });
					}

					sipClient.answer(session);
				}
			} else {
				s.http.get(`/pbx/app/action/answer?device=${s.myPhoneId}&target=${this.call._id}`).catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.header'), message: i18n.t('mycalls.failedToAnswerCall') });
				});
			}
		},
		async hold() {
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
				if (session) {
					sipClient.hold(session);
				}
			} else {
				s.http.get(`/pbx/app/action/hold?device=${s.myPhoneId}&target=${this.call._id}`).catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.header'), message: i18n.t('mycalls.failedToHoldCall') });
				});
			}
		},
		async unhold() {
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
				if (session) {
					// Tell all calls not either this call, or a call already on hold, or a ringing call (cannot put a ringing call on hold) to all go on hold
					const sessionsNotOnHoldOrRinging = sipClient.getSessions().filter(o => o != session && !['CALL_HOLD', 'CALL_RING'].includes(o.data.tcxState));

					for (const session of sessionsNotOnHoldOrRinging) {
						EventBus.$emit('Call:ActivateHold', { sessionId: session._id });
					}
					sipClient.unhold(session);
				}
				// session.data.putOnHoldAt = null;
			} else {
				s.http.get(`/pbx/app/action/unhold?device=${s.myPhoneId}&target=${this.call._id}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.header'), message: i18n.t('mycalls.failedToResumeCall') });
				});
			}
			// this.call.putOnHoldAt = null;
		},
		async hangup() {
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
				if (session) {
					if (this.call.state == 'CALL_RING') {
						sipClient.decline(session);
						EventBus.$emit('CallRejected', { source: this.call._id });
					} else {
						sipClient.hangup(session);
					}
				}
			} else {
				if (this.call.state == 'CALL_RING') {
					EventBus.$emit('CallRejected', { source: this.call._id });
				}
				s.http.get(`/pbx/app/action/hangup?target=${this.call._id}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.header'), message: i18n.t('mycalls.failedToHangupCall') });
				});
			}
		},
		incomingCallPopup() {
			let myPhoneType;
			this.myPhoneType()
				.then(type => { myPhoneType = type; })
				.then(() => employees.getEmployeeByExtensionNumber(this.call.caller.number))
				.then(employee => {
					let title = employee ? employee.name : this.call.caller.name ? this.call.caller.name + ' (' + this.call.caller.number.replace(s.pbxSettings.nationalPrefix, '') + ')' : this.call.caller.number.replace(s.pbxSettings.nationalPrefix, '');
					let body = (myPhoneType == 'SIPPHONE' || myPhoneType == 'SOFTPHONE') ? i18n.t('mycalls.clickToAnswer') : null;
					if (this.call.caller.privacy) { title = i18n.t('mycalls.hiddenNumber'); }
					let icon = employee && employee.picture ? `https://image.telecomx.dk/${employee.picture}.png?width=256&height=256&token=${s.token}` : null;
					const that = this;
					const onClick = myPhoneType == 'SIPPHONE' || myPhoneType == 'SOFTPHONE' ? () => { that.incomingCallPopupNotification = null; that.answer(); } : null;
					const onClose = () => { that.incomingCallPopupNotification = null; };

					// Show popup
					this.incomingCallPopupNotification = MyNotification.show(title, body, icon, true, onClick, onClose);

					// Close popup when state changes
					this.incomingCallPopupWatcher = this.$watch('call.state', (newValue) => {
						if (this.incomingCallPopupNotification && newValue != 'CALL_START' && newValue != 'CALL_RING') {
							this.incomingCallPopupNotification.close();
							this.incomingCallPopupWatcher();
						}
					});
				})
				.catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.genericErrorHeader'), message: err.message });		
				});
		},
		async myPhoneType() {
			if (s.myPhoneId == 'SOFTPHONE') { return 'SOFTPHONE'; }
			const me = await employees.getEmployee(s.auth.employee);
			let type = null;
			if (me) {
				me.extensions.forEach(ext => {
					ext.phones.forEach(phone => {
						if (phone._id == s.myPhoneId) { type = phone.type; }
					}); 
				});
			}
			return type;
		},
		noPhoneSelected() {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') });
		},
		getAndShowVariable() {
			if (this.variable && this.call.callerChannelId) {
				s.http.get(`/pbx/callvariable/${this.call._id.split(':')[0]}:${this.call.callerChannelId}/${this.variable}`)
					.then(res => {
						//console.log(`Got variable: ${res.data.value}`);
						if (res.data.value != null) {
							this.variableValue = res.data.value;
						}
					})
					.catch(() => { }); // no variable present
			}
		},
		sendDtmfEvent(key) {
			if (this.call.state == 'CALL_ANSWER') {
				const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
				if (session) {
					sipClient.sendDTMF(key, session);
					sipClient.playDtmfTone(key);
				}
			}
		},
		activateHoldEvent(event) {
			if (!event || event.exceptCall && this.call._id !== event.exceptCall || this.call._id === event.call || this.shouldRunEventFromSipClient(event)) {
				if (this.call.state == 'CALL_RING') {
					this.hangup();
				} else {
					this.hold();
				}
			}
		},
		deactivateHoldEvent(event) {
			// The emitter does not know the call._id of this call, but only the session
			if (this.shouldRunEventFromSipClient(event)) {
				this.answer();
			}
			this.unhold();
		},
		answerEvent(event) {
			// The emitter does not know the call._id of this call, but only the session
			if (this.shouldRunEventFromSipClient(event)) {
				this.answer();
			}
		},
		hangupEvent(event) {
			// The emitter does not know the call._id of this call, but only the session
			if (this.shouldRunEventFromSipClient(event)) {
				this.hangup();
			}
		},
		rejectEvent(event) {
			// The emitter does not know the call._id of this call, but only the session
			if (this.shouldRunEventFromSipClient(event)) {
				this.hangup();
			}
		},
		
		/** This function is used for eventHandlers that are called through emitting EventBus events from sipClient.js.
		 *  The SIP client emits events based on sessionId, so each event handler here has to match their own call with the corresponding session.
		 */
		shouldRunEventFromSipClient(event) {
			const session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state);
			if (!event || event.sessionId && event.sessionId === session?._id || !event.exceptSessionId && !event.sessionId) {
				return true;
			}
			return false;
		}
	},
	created() {
		//console.log(JSON.stringify(this.call, null, 4));
		EventBus.$on('OneSecond', this.bindCache.bind('refreshDuration', this.refreshDuration));
		if (s.myPhoneId == 'SOFTPHONE') {
			this.session = sipClient.getSession(this.call.callId, this.call.caller.number, this.call.state === 'CALL_START' ? 'CALL_RING' : this.call.state);
			EventBus.$on('Call:ActivateHold', this.bindCache.bind('activateHoldEvent', this.activateHoldEvent));
			EventBus.$on('MyCallsSendDTMF', this.bindCache.bind('sendDtmfEvent', this.sendDtmfEvent));
			EventBus.$on('Call:DeactivateHold', this.bindCache.bind('deactiveHoldEvent', this.deactivateHoldEvent));
			EventBus.$on('Call:Answer', this.bindCache.bind('answerEvent', this.answerEvent));
			EventBus.$on('Call:Hangup', this.bindCache.bind('hangupEvent', this.hangupEvent));
			EventBus.$on('Call:Reject', this.bindCache.bind('rejectEvent', this.rejectEvent));

			// If I am making a new call, then all other calls to go on hold
			if (this.call.leg == 'I') {
				EventBus.$emit('Call:ActivateHold', { exceptCall: this.call._id });
			}
		}
		if (this.autoAnswer.enabled) {
			logger.info('MyCallsCall: Auto-answer enabled - starting timer');
			this.timer2 = setInterval(() => { this.autoAnswerCheck(); }, 50);
		}
		setTimeout(() => { this.refreshDuration(); }, 100);
		if (document.hidden && this.call.leg == 'O' && (this.call.state == 'CALL_START' || this.call.state == 'CALL_RING')) {
			this.incomingCallPopup();
		}
		if (!this.call.callee.name && this.call.callee.number.length < 8) {
			const name = employees.findNameFromNumber(this.call.callee.number);
			if (name) { this.call.callee.name = name; }
		}
	},
	mounted() {
		this.getAndShowVariable();
	},
	destroyed() {
		EventBus.$off('OneSecond', this.bindCache.unbind('refreshDuration'));
		if (s.myPhoneId == 'SOFTPHONE') {
			EventBus.$off('Call:ActivateHold', this.bindCache.unbind('activateHoldEvent'));
			EventBus.$off('MyCallsSendDTMF', this.bindCache.unbind('MyCallsSendDTMF'));
			EventBus.$off('Call:DeactivateHold', this.bindCache.unbind('deactiveHoldEvent'));
			EventBus.$off('Call:Answer', this.bindCache.unbind('answerEvent'));
			EventBus.$off('Call:Hangup', this.bindCache.unbind('hangupEvent'));
			EventBus.$off('Call:Reject', this.bindCache.unbind('rejectEvent'));
			if (this.session) {
				EventBus.$off(`Call:${this.session._id}:ActivateHold`, this.bindCache.unbind('activateHoldEvent'));
				EventBus.$off(`Call:${this.session._id}:DeactivateHold`, this.bindCache.unbind('deactiveHoldEvent'));
				EventBus.$off(`Call:${this.session._id}:Answer`, this.bindCache.unbind('answerEvent'));
				EventBus.$off(`Call:${this.session._id}:Hangup`, this.bindCache.unbind('hangupEvent'));
				EventBus.$off(`Call:${this.session._id}:Reject`, this.bindCache.unbind('rejectEvent'));
			}
		}
		if (this.timer2) { clearInterval(this.timer2); }
		if (this.incomingCallPopupNotification) { this.incomingCallPopupNotification.close(); }
		if (this.incomingCallPopupWatcher) { this.incomingCallPopupWatcher(); }
	},
	watch: {
		'call.state': function(newValue, oldValue) {
			if (this.call.leg == 'I' && this.call.callee.number.match(/^\*7(1|2|3)/) && newValue == 'CALL_ANSWER' && oldValue != 'CALL_HOLD') {
				setTimeout(() => {
					s.http.get(`/pbx/app/employees?calls=true&ids=${s.auth.employee}`)
						.then(res => {
							res.data[0].extensions.forEach(extension => {
								const call = extension.calls.find(o => o._id == this.call._id);
								if (call) {
									this.call.callee.number = call.callee.number;
									this.call.callee.name = call.callee.name;
									this.call.callerChannelId = call.callerChannelId;
								}
							});
							this.getAndShowVariable();
						});
				}, 500);
			}
		}
	}

};
</script>

<style scoped>
	.call-box {
		display: grid;
		grid-template-columns: 40px 280px 65px;
		transition: 400ms;
		transition-delay: 1s;
		padding-left: 5px;
		height: 50px;
		overflow: hidden;
	}
	.state-icon {
		align-self: center;
		border-radius: 20px;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.state-icon > DIV {
		margin-top: -4px;
	}
	.bottom-line {
		margin-left: 52px;
		border-bottom: 1px solid #d0d0d0;
	}
	.labels {
		align-self: center;
		padding-left: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: -2px;
		width: 100%;
	}
	.labels > DIV {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.name {
		font-size: var(--font-size-primary);
		font-weight: 300;
		line-height: var(--font-size-primary);
		color: var(--txt-color-primary);
		letter-spacing: 0.01em;
	}
	.no-name {
		font-size: var(--font-size-primary);
		font-weight:400;
		line-height: var(--font-size-primary);
		color: var(--txt-color-primary);
		letter-spacing: 0.01em;
	}
	.number {
		font-size: var(--font-size-small);
		color: var(--label-message-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		letter-spacing: 0.01em;
	}
	.country-flag {
		margin-left: 3px;
		margin-top: -1px;
	}
	.queue {
		color: var(--label-queue-color);
		font-weight: 500;
	}
	.variable {
		font-size: var(--font-size-small);
		font-style: italic;
		color: var(--label-location-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		transition: 400ms;
	}
	.variable > SPAN {
		font-weight: 600;
	}
	.label {
		padding-left: 2px;
	}
	.duration-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: 1000ms;
		width: 130px;
		overflow: hidden;
	}
	.duration-box.visible {
		width: 42px;
	}
	.duration-box > DIV {
		color: var(--call-duration-color);
		font-family: RobotoMonoThin;
		font-size: var(--font-size-small);
		flex-shrink: 0;
		text-align: right;
	}
	.ringing {
		animation: shake 1s; 
		animation-iteration-count: infinite; 
	}

	@keyframes shake {
		0% { transform: translate(2px, 0px); }
		10% { transform: translate(-2px, 1px); }
		20% { transform: translate(2px, -1px); }
		30% { transform: translate(-2px, 0px); }
		40% { transform: translate(2px, 0px); }
		50% { transform: translate(-2px, 0px); }
		60% { transform: translate(2px, 0px); }
		70% { transform: translate(-2px, 1px); }
		80% { transform: translate(2px, -1px); }
		90% { transform: translate(-2px, 0px); }
		100% { transform: translate(2px, 0px); }
	}
	.onhold {
		animation: holdanim 1s; 
		animation-iteration-count: infinite;
		margin-left: -5px;
		margin-top: 3px !important;
	}
	@keyframes holdanim {
		0% { opacity: 1; }
		40% { opacity: 0.2; }
		70% { opacity: 1; }
	}
</style>

/**
 * Sennheiser headset API
 * Created by Per Moeller <pm@telecomx.dk> on 2022-08-01
 * 
 * You can subscibe for (addEventListener):
 * - 'answer', 'reject', 'hangup', 'hold', 'resume' events from the headset
 * - 'headset' events with type connected/disconnected and name
 * - 'status' events with state CONNECTING, TERMINATED, DISCONNECTED, RECONNECTING, CONNECTED
 */

class SennheiserEposApi extends EventTarget {
	/**
	 * Initialize a new Sennheiser EPOS headset API control module
	 * @param {String} vendorName Name of vendor
	 * @param {String} productName Name of Softphone product
	 * @param {String} productVersion Product version
	 * @param {Boolean} log True to log to console, false for no logging
	 */
	constructor(vendorName, productName, productVersion, log) {
		super();
		this.vendorName = vendorName;
		this.productName = productName;
		this.productVersion = productVersion;
		this.shouldReconnect = true;
		this.isLoggedIn = false;
		this.logging = log || false;
		this.currentHeadset = '';
		this.bound = {
			open: this.onWebSocketOpen.bind(this),
			close: this.onWebSocketClose.bind(this),
			message: this.onWebSocketMessage.bind(this)
		};
		this.callIdTranslation = {};
		this.connect();
	}

	/**
	 * Connect to the Headset websocket API
	 * @private
	 */
	connect() {
		this.dispatchEvent(new CustomEvent('status', { detail: { state: 'CONNECTING' }}));
		if (this.ws) {
			this.ws.removeEventListener('open', this.bound.open);
			this.ws.removeEventListener('message', this.bound.message);
			this.ws.removeEventListener('close', this.bound.close);
		}
		this.ws = new WebSocket('wss://127.0.0.1:41088');
		this.ws.addEventListener('open', this.bound.open);
		this.ws.addEventListener('message', this.bound.message);
		this.ws.addEventListener('close', this.bound.close);
	}

	/**
	 * Terminate the connection to the headset
	 */
	async terminate() {
		this.shouldReconnect = false;
		if (!this.isConnected) { return; }
		if (this.isLoggedIn) {
			this.logout();
			await this.sleep(50);
		}
		this.terminateConnection();
		await this.sleep(50);
		this.ws.close(1000, 'Terminated by user');
		this.dispatchEvent(new CustomEvent('status', { detail: { state: 'TERMINATED' }}));
	}

	/**
	 * Are we connected to the headset
	 */
	get isConnected() { return this.ws && this.ws.readyState === 1; }

	onWebSocketOpen() {
		this.log('Websocket opened');
	}

	async onWebSocketClose(event) {
		this.log(`Websocket closed: ${event.code} ${event.reason} - clean: ${event.wasClean}`);
		this.isLoggedIn = false;
		this.dispatchEvent(new CustomEvent('status', { detail: { state: 'DISCONNECTED' }}));
		if (this.shouldReconnect) {
			await this.sleep(500);
			this.dispatchEvent(new CustomEvent('status', { detail: { state: 'RECONNECTING' }}));
			this.connect();
		}
	}

	onWebSocketMessage(event) {
		// data, origin, lastEventId, source, ports
		const data = JSON.parse(event.data);

		if (data.EventType === 'Acknowledgement') {
			this.handleAcknowledgement(data);
		} else {
			this.handleNotification(data);
		}
	}

	handleAcknowledgement(data) {
		// DEBUG
		//console.log('SennheiserEposApi: ACKNOWLEDGEMENT:', data);
		
		// Event, EventType, CallID, ReturnCode
		switch (data.Event) {
			case 'EstablishConnection':
				this.log(`${data.Event} acknowledged - ${this.returnCodeToString(data.ReturnCode)}`);
				this.wsSend({ Event: 'SystemInformation', EventType: 'Request' });
				break;
			case 'SystemInformation':
				this.log(`SystemInformation ${data.ProductName} - ${data.ProductVersion}`);
				setTimeout(() => {
					this.login();
				}, 1000);
				break;
			case 'SPLoggedIn':
				this.isLoggedIn = true;
				this.log(`${data.Event} acknowledged - ${this.returnCodeToString(data.ReturnCode)}`);
				this.dispatchEvent(new CustomEvent('status', { detail: { state: 'CONNECTED' }}));
				break;
			case 'SPLoggedOut':
				this.isLoggedIn = false;
				this.log(`${data.Event} acknowledged - ${this.returnCodeToString(data.ReturnCode)}`);
				break;
			case 'ActiveDeviceChanged':
				this.log(`${data.Event} acknowledged - ${data.HSSTATUS} - ${data.HeadsetPath}`);
				break;
			case 'TerminateConnection':
			case 'ListenerClosed':
			case 'IncomingCall':
			case 'InCallAccepted':
			case 'InCallAcceptedOnOffhook':
			case 'InCallRejected':
			case 'CallEnded':
			case 'OutgoingCall':
			case 'OutCallAccepted':
			case 'OutCallRejected':
			case 'HeldCallResumed':
			case 'CallHold':
			case 'OffHook':
				this.log(`${data.Event} completed - result = ${this.returnCodeToString(data.ReturnCode)}`);
				break;
			default:
				this.log(`Got unknown event: ${JSON.stringify(data)} - ${this.returnCodeToString(data.ReturnCode)}`);
				break;
		}
	}

	handleNotification(data) {
		//console.log('SennheiserEposApi: NOTIFICATION:', data);
		const returnCodeString = ' - RetCode: ' + this.returnCodeToString(data.ReturnCode);

		switch (data.Event) {
			case 'SocketConnected':
				this.log('Websocket connected' + returnCodeString);
				this.establishConnection();
				break;
			case 'InCallAccepted':
			case 'InCallAcceptedOnOffhook':
				this.log('Headset requests call being answered');
				this.dispatchEvent(new Event('answer'));
				break;
			case 'InCallRejected':
				this.log('Headset requests call being rejected');
				this.dispatchEvent(new Event('reject'));
				break;
			case 'CallEnded':
				this.log('Headset requests call being hung up');
				this.dispatchEvent(new Event('hangup'));
				break;
			case 'HeldCallResumed':
				this.log('Headset requests call resumed from hold');
				this.dispatchEvent(new Event('resume'));
				break;
			case 'CallHold':
				this.log('Headset requests call put on hold');
				this.dispatchEvent(new Event('hold'));
				break;
			case 'HeadsetAvailable':
				this.log(`HeadsetConnected: Name: ${data.HeadsetName} - type: ${data.HeadsetType} - path: ${data.HeadsetPath}`);
				this.dispatchEvent(new CustomEvent('headset', { detail: { type: 'available', name: data.HeadsetType, path: data.HeadsetPath }}));
				break;
			case 'HeadsetConnected':
				this.log(`HeadsetConnected: Name: ${data.HeadsetName} - type: ${data.HeadsetType} - path: ${data.HeadsetPath}`);
				this.currentHeadset = data.HeadsetType;
				this.dispatchEvent(new CustomEvent('headset', { detail: { type: 'connected', name: data.HeadsetType, path: data.HeadsetPath }}));
				break;
			case 'HeadsetDisconnected':
				this.log(`HeadsetConnected: Name: ${data.HeadsetName} - type: ${data.HeadsetType} - path: ${data.HeadsetPath}`);
				this.currentHeadset = '';
				this.dispatchEvent(new CustomEvent('headset', { detail: { type: 'disconnected', name: data.HeadsetType, path: data.HeadsetPath }}));
				break;
			case 'OffHook':
				this.log(`Off hook event: ${data.IDForResponse} - ${data.HeadsetPath}`);
				break;
			case 'ActiveDeviceChanged':
				this.log(`Active Device Changed: ${data.HSSTATUS} - ${data.HeadsetPath}`);
				break;
			default:
				this.log(`Got unknown event: ${JSON.stringify(data)} - ${returnCodeString}`);
				break;
		}
	}

	// #region Commands

	/**
	 * Establish connection to the headset
	 * @private
	 */
	establishConnection() {
		this.wsSend({
			Event: 'EstablishConnection',
			EventType: 'Request',
			SPName: 'Softphone::CCL',
			SPIconImage: 'SPImage.ico', // path to a 16x16 pixels .ico file on the local harddrive
			RedialSupport: 'Yes',
			OffHookSupport: 'Yes',
			MuteSupport: 'Yes',
			AudioDeviceChangesSupport: 'Yes',
			DNDOption: 'Yes',
			ProtocolVersion: '2.0'
		});
	}

	/**
	 * Terminate the connection to the headset
	 * @private
	 */
	terminateConnection() {
		if (!this.isConnected) { return; }
		this.wsSend({
			Event: 'TerminateConnection',
			EventType: 'Request'
		});
	}

	login() {
		this.wsSend({ Event: 'SPLoggedIn', EventType: 'Request' });
	}

	/**
	 * Log the softphone out
	 * @private
	 */
	logout() {
		this.wsSend({
			Event: 'SPLoggedOut',
			EventType: 'Request'
		});
	}

	/**
	 * Convert callId to cId
	 * @param {string} callId Call id from sip client
	 * @returns {string}
	 */
	getCid(callId) {
		if (!this.callIdTranslation[callId]) {
			this.callIdTranslation[callId] = '_' + Math.random().toString(36).substring(2, 2+9);
		}
		return this.callIdTranslation[callId];
	}

	/**
	 * Delete callId to cId
	 * @param {string} callId Call id from sip client
	 */
	deleteCid(callId) {
		delete this.callIdTranslation[callId];
	}

	/**
	 * Start inbound call, start the ringer
	 * @param {String} callId CallID for this call
	 */
	inboundStart(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Inbound start for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'IncomingCall',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * The call was answered - stop ringer open audio
	 * @param {String} callId CallID for this call
	 */
	offHook(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Answer for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'InCallAccepted',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * The inbound call was rejected - stop ringer - stop audio
	 * @param {String} callId CallID for this call
	 */
	inboundReject(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Inbound reject for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'CallEnded',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * Start outbound call - start audio
	 * @param {String} callId CallID for this call
	 */
	outboundStart(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Outbound start for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'OutgoingCall',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * Outbound call was rejected - stop audio
	 * @param {String} callId CallID for this call
	 */
	outboundReject(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Outbound reject for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'CallEnded',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * Select the headset to control
	 * @param {String} name Name of device to control (name from audio devices list)
	 */
	selectDevice(name) {
		// if (!this.isConnected || !this.isLoggedIn) { return; }
		// if (name.match(/^\d- /)) { name = name.substring(3); }

		// this.wsSend({
		// 	Event: 'ActiveDeviceChanged',
		// 	EventType: 'Request',
		// 	HSSTATUS: 1, // 1=supported, 2=unsupported
		// 	HeadsetPath: name.toLowerCase()
		// });
	}


	// Common in call api's

	/**
	 * The call was hung up - stop audio
	 * @param {String} callId CallID for this call
	 */
	hangup(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Hangup for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'CallEnded',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * The call was put on hold
	 * @param {String} callId CallID for this call
	 */
	hold(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Hold for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'CallHold',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}

	/**
	 * The call was resumed from hold
	 * @param {String} callId CallID for this call
	 */
	unhold(callId) {
		if (!this.isConnected || !this.isLoggedIn) { return; }
		const cId = this.getCid(callId);
		this.log(`Unhold for callID ${callId} - ${cId}`);
		this.wsSend({
			Event: 'InCallAccepted',
			EventType: 'Request',
			CallID: `${cId}`
		});
	}
	

	// #endregion

	// #region Misc
	
	log(str) {
		if (this.logging) {
			console.log('SennheiserEposApi: ' + str); // eslint-disable-line
		}
	}

	/**
	 * Send request to headset
	 * @param {Object} obj Request object
	 */
	wsSend(obj) {
		if (this.isConnected) {
			//console.log(`SennheiserEposApi: Sending: ${JSON.stringify(obj)}`);
			this.ws.send(JSON.stringify(obj));
		}
	}

	sleep(ms) { return new Promise(resolve => { setTimeout(() => { resolve(); }, ms); }); }

	returnCodeToString(code) {
		switch(code) {
			case 0: return 'OK';
			case 1 : return 'DefaultError';
			case 2 : return 'InvalidSoftphoneID';
			case 3 : return 'InvalidSoftphone';
			case 4 : return 'DuplicteSoftphone';
			case 5 : return 'SoftPhoneNotLogedIn';
			case 6 : return 'InvalidCallID';
			case 7 : return 'InvalidCallState';
			case 8 : return 'DuplicateCallID';
			case 9 : return 'DuplicateSoftphoneName, 10 = InsufficientData';
			case 11: return 'InvalidJsonMessage';
			case 12: return 'RestHandlerNotSet';
			case 13: return 'RedialFailed';
			case 14: return 'OffHookFailed';
			case 15: return 'InvalidRequestID';
			default: return 'Unknown';
		}
	}

	// #endregion
}

export default SennheiserEposApi;

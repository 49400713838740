import EventBus from './EventBus';
import JsSIP from 'jssip';
import s from '../settings';
import BindCache from '../utils/BindCache';
import jabra from '../utils/headsets/jabra';
import sennheiser from '../utils/headsets/sennheiser';
import yealink from '../utils/headsets/yealink';
import kuando from '../utils/headsets/kuando';
import mediaDevices from '../data/mediaDevices';
import i18n from '../utils/i18n';
import browserDetect from '../utils/browserdetect';
import logger from './logger';

const SIP_PROXY = 'wssip.telecomx.dk';
const RINGTONES = [
	{ id: '60af913685fa792a658f75e2', name: i18n.t('sipClient.ringtones.old') },
	{ id: '60af919585fa792a658f75e3', name: i18n.t('sipClient.ringtones.digital') },
	{ id: '60af91bb85fa792a658f75e4', name: i18n.t('sipClient.ringtones.digital2') },
	{ id: '60af91d485fa792a658f75e5', name: i18n.t('sipClient.ringtones.marimba') },
	{ id: '60af91f285fa792a658f75e7', name: i18n.t('sipClient.ringtones.iphoneremix') },
	{ id: '60af933585fa792a658f75ea', name: i18n.t('sipClient.ringtones.behappy') },
	{ id: '619e5e4daa69b00a66f57f75', name: i18n.t('sipClient.ringtones.marimba2') },
	{ id: '619e5e6eaa69b00a66f57f76', name: i18n.t('sipClient.ringtones.guitar') },
	{ id: '619e5e82aa69b00a66f57f77', name: i18n.t('sipClient.ringtones.nokia') },
	{ id: '619e5e9caa69b00a66f57f78', name: i18n.t('sipClient.ringtones.christmastime') }
];
const CALL_WAITING_RINGTONE = '60af988785fa792a658f75f3';
const NOTIFICATION_TONE = '60af932685fa792a658f75e9';
const RINGING_TONE = '60bf38d87a40e725dd7fa219'; // '60b0dd9fd2d02e586ee051ff';
const BUSY_TONE = '60bf38aa7a40e725dd7fa217'; // '60b0e2a9d2d02e586ee05203';
const BUSY_TONE_SHORT = '60bf38ca7a40e725dd7fa218';
// 1 second of silence: 60af951485fa792a658f75eb
const DTMF_TONES = {
	'0': '60bf53db7a40e725dd7fa249',
	'1': '60bf54347a40e725dd7fa24b',
	'2': '60bf543e7a40e725dd7fa24c',
	'3': '60bf544a7a40e725dd7fa24d',
	'4': '60bf54557a40e725dd7fa24e',
	'5': '60bf54617a40e725dd7fa24f',
	'6': '60bf546d7a40e725dd7fa250',
	'7': '60bf547c7a40e725dd7fa251',
	'8': '60bf54877a40e725dd7fa252',
	'9': '60bf54937a40e725dd7fa253',
	'*': '60bf549d7a40e725dd7fa254',
	'#': '60bf54a67a40e725dd7fa255'
};

JsSIP.debug.disable('JsSIP:Transport');
//JsSIP.debug.enable('JsSIP:*');

class SipClient {
	constructor() {
		logger.debug('SipClient: Constructor');
		this.ua = null;
		this.ringtones = RINGTONES; // To expose theese to the outside world
		this.shouldBePlayingRingtone = false;
		this.bindCache = new BindCache(this);
		this.microphoneDeviceId = null;
		this.speakerDeviceId = null;
		this.videoDeviceId = null;
		this.ringtoneDeviceId = null;
		this.registered = false;
		this.sessions = [];
		this.server = null;
		this.username = null;
		this.password = null;
		this.sipPhoneDeviceId = null;
		this.extensionName = null;
		this.isRegisterScheduled = false;

		EventBus.$on('Auth:LoggedOut', () => { this.deregister(); });
		EventBus.$on('Auth:LoggedIn', () => {
			if (s.myPhoneId === 'SOFTPHONE' && !this.registered) {
				if (this.ua) {
					this.deregister();
					s.sleep(500).then(() => { this.register(); });
				} else {
					this.register();
				}
			}
		});
		EventBus.$on('MediaDeviceChange',device => {
			const micChanged = device.kind === 'audioinput';
			this.updateMediaDevices(micChanged);
		});
		EventBus.$on('MediaDeviceSelected', data => {
			const micChanged = data.type === 'audioinput';
			this.updateMediaDevices(micChanged);
		});
		EventBus.$on('Wakeup', async () => {
			if (this.ua) {
				logger.debug('SipClient: Wakeup detected - de/re-registering SIP client');
				this.deregister();
				await s.sleep(500);
				this.register();
			}
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'sipClient', value: s.myPhoneId=='SOFTPHONE' });
			if (s.myPhoneId === 'SOFTPHONE') {
				EventBus.$emit('StatusReport', { key: 'sipClientRegistered', value: this.registered });
				EventBus.$emit('StatusReport', { key: 'sipClientSessions', value: this.sessions.length });
				EventBus.$emit('StatusReport', { key: 'sipClientMicrophone', value: mediaDevices.getDeviceName(this.microphoneDeviceId) });
				EventBus.$emit('StatusReport', { key: 'sipClientSpeaker', value: mediaDevices.getDeviceName(this.speakerDeviceId) });
				EventBus.$emit('StatusReport', { key: 'sipClientVideo', value: mediaDevices.getDeviceName(this.videoDeviceId) });
				EventBus.$emit('StatusReport', { key: 'phoneId', value: s.mySoftphoneId });
			}
		});

		EventBus.$on('SipClient:Error:Retry', () => {
			window.location.reload();
		});

		EventBus.$on('SipClient:Error:Terminate', () => {
			if (s.isEmbedded) {
				try { axios.get(`${s.localUrl}/terminate`, { timeout: 5000 }); }
				catch(_) {}
			} else {
				window.location.href = 'https://blank.page';
			}
		});

		window.addEventListener('beforeunload', () => {
			if (this.use) { this.deregister(); }
		});

		logger.registerGlobal('sipClient', this);
	}

	/**
	 * Updates media devices when changes
	 * @private
	 */
	async updateMediaDevices(micChanged) {
		logger.debug('SipClient: Media device change detected - now updating');
		await mediaDevices.ready();
		this.microphoneDeviceId = mediaDevices.microphoneDeviceId;
		this.speakerDeviceId = mediaDevices.speakerDeviceId;
		this.videoDeviceId = mediaDevices.videoDeviceId;
		this.ringtoneDeviceId = mediaDevices.ringerDeviceId;
		if (micChanged && this.sessions.length > 0) {
			await this.switchAudioDevice();
		}
	}

	/**
	 * Invoke this to initialize the sip client when it is choosen as the phone to use
	 */
	async register() {
		if (this.isRegisterScheduled) {
			logger.info('SipClient: Register invoked - but ignored due to scheduled register');
			return;
		}
		logger.info('SipClient: Register invoked');
		if (this.ua) {
			logger.error('SipClient: Register invoked - but a UA already exists - cleaning up');
			this.ua.stop();
			this.ua.removeAllListeners();
			this.ua = null;
			await s.sleep(500);
		}
		await mediaDevices.init();
		await this.updateMediaDevices();
		kuando.off();

		// Make sure we have a SIP acccount to register to
		logger.info(`SipClient: Requesting SIP credentials for instance: ${s.instanceId} using v2 protocols`);
		try {
			const res = await s.http.post('/pbx/sipphone/webrtc', { instanceId: s.instanceId, v2: true });
			this.server = res.data.server;
			this.username = res.data.username;
			this.password = res.data.password;
			this.sipPhoneDeviceId = res.data._id;
			this.extensionNumber = res.data.extensionNumber;
			this.extensionName = res.data.extensionName;
			s.mySoftphoneId = this.sipPhoneDeviceId;
		}
		catch(err) {
			if ((err.status === 0 && err.code === 'network') || (err.message && err.message.toLowerCase().includes('econnreset'))) { // Network issue, try again in 5 secs
				this.isRegisterScheduled = true;
				await s.sleep(5000).then(() => { this.isRegisterScheduled = false; this.register(); }).catch(() => {});
			} else {
				logger.error(`SipClient: Failed to get SIP credentials: ${err.message}`);
				EventBus.$emit('CommonErrorModal', { header: i18n.t('sipClient.errorHeader'), message: i18n.t('sipClient.errorWebRtcInitFailedMessage') + '<br><br>' + err.message });
			}
			return;
		}
		logger.info(`SipClient: SIP credentials received for username: ${this.username} and deviceId: ${this.sipPhoneDeviceId} - now initializing JsSIP`);
		
		// Initialize JsSIP
		const socket = new JsSIP.WebSocketInterface(this.server);
		const config = {
			sockets: [socket],
			uri: `sip:${this.username}@${this.server.split('://')[1]}`,
			password: this.password,
			realm: 'telecomx.dk',
			display_name: this.extensionName,
			user_agent: `Telecom X ${s.appName} ${s.version}`,
			session_timers_refresh_method: 'invite'
		};
		this.ua = new JsSIP.UA(config);

		// Ensuring that we do not have multiple JSSIP UA's running at the same time - which has been a pain in the a..
		this.ua.tcxTag = Math.random().toString();
		this.ua.tcxCreated = new Date();
		if (window.communicator.sipClientUA) {
			const other = window.communicator.sipClientUA;
			logger.error(`SipClient: Register detected other sipClient with tag ${other.tcxTag} created: ${other.tcxCreated.toISOString()} - we will now try to kill it!`);
			other.stop();
			other.removeAllListeners();
		}
		window.communicator.sipClientUA = this.ua;
		
		this.ua.on('connecting', data => { // (socket, attempts)
			EventBus.$emit('SipClient', { event: 'CONNECTING', attempt: data.attempts });
			logger.info(`SipClient: Connecting - attempt ${data.attempts}`);
		});

		this.ua.on('connected', _ => { // (socket)
			EventBus.$emit('SipClient', { event: 'CONNECTED' });
			logger.info('SipClient: Connected');
		});

		this.ua.on('disconnected', data => { // (socket, error, code, reason)
			EventBus.$emit('SipClient', { event: 'DISCONNECTED', code: data.code, reason: data.reason });
			logger.info(`SipClient: Disconnected: ${data.error?'Due to error - ':''}${data.code} - ${data.reason}`);
		});

		this.ua.on('registered', data => { // Response
			this.registered = true;
			EventBus.$emit('SipClient', { event: 'REGISTERED' });
			logger.info(`SipClient: Registered - ${data.response.status_code} ${data.response.reason_phrase}`);
		});

		this.ua.on('unregistered', data => { // (response, cause)
			this.registered = false;
			EventBus.$emit('SipClient', { event: 'UNREGISTERED', cause: data.cause });
			logger.info(`SipClient: Unregistered - ${data.response ? data.response.status_code : 'n/a'} ${data.response ? data.response.reason_phrase : 'n/a'} - ${data.cause}`);
		});

		this.ua.on('registrationFailed', data => {
			this.registered = false;
			if (data.response.status_code === 403) {
				this.ua.stop();
				logger.error(`SipClient: Registration failed - ${data.cause} - ${data.response.status_code} ${data.response.reason_phrase} - SIP client terminated`);
			} else {
				logger.error(`SipClient: Registration failed - ${data.cause} - ${data.response.status_code} ${data.response.reason_phrase}`);
			}
			EventBus.$emit('SipClient', { event: 'REGISTERFAILED', code: data.response.status_code, reason: data.response.reason_phrase });
			if (data.response.status_code === 403) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('sipClient.errorHeader'), message: i18n.t('sipClient.errorFailedToAuthenticateFatal') });
				EventBus.$emit('CommonQuestionModal', {
					header: i18n.t('sipClient.errorHeader'),
					message: i18n.t('sipClient.errorFailedToAuthenticateFatal'),
					ok: i18n.t('sipClient.errorRetry'),
					cancel: i18n.t('sipClient.errorTerminate'),
					emit: 'SipClient:Error:Retry',
					cancelledEmit: 'SipClient:Error:Terminate'
				});
			} else {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('sipClient.errorHeader'), message: i18n.t('sipClient.errorFailedToAuthenticate').replace('%%code%%', data.response.status_code).replace('%%reason%%', data.response.reason_phrase) });
			}
		});

		this.ua.on('newRTCSession', data => { // incoming call (originator, session, request)
			if (data.originator === 'local') {
				if (!data.session.data) { data.session.data = {}; }
				data.session.data.callId = data.request.getHeader('Call-ID');
				logger.info(`SipClient: Outbound call with Call-ID ${data.session.data.callId} initiated`);
				const rtcPeerConnection = data.session.connection;
				rtcPeerConnection.addEventListener('track', async event => {
					logger.info('SipClient: Received track with remote audio for outbound call');
					this.createRemoteAudioPlayer(data.session, event.streams[0]);
				});
			} else {
				this.onIncomingCall(data);
			}
		});

		this.ua.start();
	}

	/**
	 * Invoke this to terminate the SIP client when it is not needed anymore
	 */
	deregister() {
		logger.info('SipClient: Deregister invoked');
		if (this.ua) {
			this.ua.stop();
			this.ua.removeAllListeners();
			this.ua = null;
		}
		this.registered = false;
		this.sessions = [];
		this.sipPhoneDeviceId = null;
		s.mySoftphoneId = null;
		kuando.off();
		if (jabra.use) { jabra.onHook(); }
		if (yealink.use) { yealink.onHook(); }
		if (sennheiser.use) { sennheiser.reInit(); }
	}

	/**
	 * Invoked when an incoming call is recieved - will initialize, announce and add to list of calls
	 * @param {Object} data Event data (originator, session, request)
	 */
	onIncomingCall(data) {
		// Parse data, store session and emit event
		const caller = this.parseUri(data.request.getHeader('From'));
		const callee = this.parseUri(data.request.getHeader('To'));
		logger.info(`SipClient: Incoming call ${caller.name} ${caller.number} -> ${callee.name} ${callee.number}`);
		const session = data.session;
		if (!session.data) { session.data = {}; }
		session.data.callId = data.request.getHeader('Call-ID');
		session.data.direction = 'INBOUND'; // inbound, outbound
		session.data.originator = data.originator; // local, remote
		session.data.tcxState = 'CALL_RING';
		session.data.caller = caller;
		session.data.callee = callee;
		this.sessions.push(session);

		EventBus.$emit('SipClient', { event: 'INBOUND', session: session });
		
		// Reply with ringing state
		logger.debug('SipClient: Replying 180 Ringing');
		data.request.reply(180, 'Ringing'); // reply(code, reason, extraHeaders, body, onSuccess, onFailure) {
		
		if (this.sessions.length === 1) {
			this.playRingTone(false, caller.number);
			if (jabra.use) { jabra.ringOn(); }
			if (yealink.use) { yealink.ringOn(); }
			if (sennheiser.use) { sennheiser.inboundStart(session.id); }
			kuando.ringing();
		} else {
			this.playRingTone(true, caller.number); // call waiting
		}
	
		session.on('ended', data => { // originator, message, cause
			switch (data.cause) {
				case JsSIP.C.causes.BYE: this.onCallEnd(session, 'BYE'); break;
				case JsSIP.C.causes.CANCELED: this.onCallEnd(session, 'CANCELLED'); break;
				case JsSIP.C.causes.NO_ANSWER: this.onCallEnd(session, 'NO_ANSWER'); break;
				case JsSIP.C.causes.EXPIRES: this.onCallEnd(session, 'NO_ANSWER'); break;
				default: session.data.endCause = data.cause; this.onCallEnd(session, 'ERROR'); break;
			}
		});

		session.on('failed', data => { // originator, message, cause
			switch (data.cause) {
				case JsSIP.C.causes.BUSY: this.onCallEnd(session, 'BUSY'); break;
				case JsSIP.C.causes.REJECTED: this.onCallEnd(session, 'REJECTED'); break;
				case JsSIP.C.causes.REDIRECTED: this.onCallEnd(session, 'REDIRECTED'); break;
				case JsSIP.C.causes.UNAVAILABLE: this.onCallEnd(session, 'UNREACHABLE'); break;
				case JsSIP.C.causes.NOT_FOUND: this.onCallEnd(session, 'NOT_FOUND'); break;
				case JsSIP.C.causes.NO_ANSWER: this.onCallEnd(session, 'NO_ANSWER'); break;
				case JsSIP.C.causes.CANCELED: this.onCallEnd(session, 'CANCELLED'); break;
				default: session.data.endCause = data.cause; this.onCallEnd(session, 'ERROR'); break;
			}
		});

		session.on('accepted', data => { // 200 ok
			session.data.tcxState = 'CALL_ANSWER';
			EventBus.$emit('SipClient', { event: 'ANSWERED', session: session });
			logger.info(`SipClient: Inbound call accepted - ${data.response ? data.response.status_code : 'n/a'} ${data.response ? data.response.reason_phrase : 'n/a'}`);
			this.handleCallWaiting(); // We want to handle call waiting tones here, if needed
		});
		session.on('confirmed', data => { // ACK
			logger.info(`SipClient: Inbound call confirmed (ACK received) - ${data.originator}`);
		});

		session.on('hold', _ => { session.data.tcxState = 'CALL_HOLD'; EventBus.$emit('SipClient', { event: 'HOLD', session: session }); });
		session.on('unhold', _ => { session.data.tcxState = 'CALL_ANSWER'; EventBus.$emit('SipClient', { event: 'UNHOLD', session: session }); });
		session.on('muted', _ => { EventBus.$emit('SipClient', { event: 'MUTED', session: session }); }); // audio, video (bools)
		session.on('unmuted', _ => { EventBus.$emit('SipClient', { event: 'UNHOLD', session: session }); }); // audio, video (bools)
	}

	/**
	 * Invoked when a call has ended
	 * @param {Object} session RTC session from jsSIP
	 * @param {String} reason Reason why call ended: BUSY, REJECTED, REDIRECTED, UNREACHABLE, NOT_FOUND, BYE, CANCELLED, NO_ANSWER, ERROR
	 */
	async onCallEnd(session, reason) {
		logger.info(`SipClient: ${session.data.direction} call by ${session.data.originator} ended due to ${reason}`);
		// If client is A number, stop ringing tone, if client is B number, stop ringTone
		this.stopRingTone();
		this.stopRingingTone();
		if (reason === 'BUSY') {
			this.playBusyTone();
		} else if (this.sessions.length === 1) {
			logger.info(`SipClient: ${session.data.direction} was the only call remaining - hanging up headset if used`);
			if (session.data.tcxState === 'CALL_HOLD') {
				if (jabra.use) { jabra.unhold(); }
				if (yealink.use) { yealink.unhold(); }
				if (sennheiser.use) { sennheiser.unhold(session.id); }
				await s.sleep(200);
			}
			if (jabra.use) { jabra.onHook(); }
			if (yealink.use) { yealink.onHook(); }
			if (sennheiser.use) { sennheiser.hangup(session.id); }
			kuando.idle();
		} else {
			logger.info(`SipClient: ${session.data.direction} was not the only remaining call - not hanging up headset`);
		}
		session.data.tcxState = 'CALL_END';

		this.sessions = this.sessions.filter(s => s !== session); // Order is important here - the async call has to be last, so the checking of this.sessions' length and the filtering are done atomically
		
		EventBus.$emit('SipClient', { event: 'END', session: session, reason: reason });

		await this.destroyRemoteAudioPlayer(session);
		
		await this.handleCallWaiting(session);
	}
	
	/**
	 * If we have a ringing call, then whenever we pick up a new call, end a call etc, we should play the call waiting tone. If we have no calls left, we should play a ringing gtone
	 */
	async handleCallWaiting(previousCall) {
		logger.debug('SipClient.handleCallWaiting: Running handleCallWaiting');
		const ringingCall = this.sessions.find(s => s.data.tcxState === 'CALL_RING');
		
		const hasActiveCall = this.sessions.find(s => ['CALL_ANSWER', 'CALL_HOLD'].includes(s.data.tcxState));
		// If we have ended a call, and have ringing calls, we should play the ring tone (or call waiting, if we still have an answered call)
		if (ringingCall) {
			// We just destroyed the ring tone player, but if we still have a ringing call, we should play a ringtone - depending on if we have an answered call or not
			const inAnsweredCall = this.sessions.find(s => s.data.tcxState === 'CALL_ANSWER') != null;
			this.playRingTone(inAnsweredCall, ringingCall.data.caller.number);

			logger.debug('SipClient.handleCallWaiting: Found a ringing call');
				
			if (jabra.use) { 
				if (!hasActiveCall) {
					logger.debug('SipClient.handleCallWaiting: No active call - hanging up before ringing');
					jabra.onHook();
				}
				setTimeout(() => {
					const stillHasRingingCall = this.sessions.find(s => s.data.tcxState === 'CALL_RING');
					if (stillHasRingingCall) {
						jabra.ringOn();
					}
				}, 500);
			}
			if (yealink.use) { 
				if (!hasActiveCall){
					logger.debug('SipClient.handleCallWaiting: No active call - hanging up before ringing');
					yealink.onHook();
				}
				setTimeout(() => {
					const stillHasRingingCall = this.sessions.find(s => s.data.tcxState === 'CALL_RING');
					if (stillHasRingingCall) {
						yealink.ringOn();
					}
				}, 500);
			}
			if (sennheiser.use) {
				if (!hasActiveCall){
					logger.debug('SipClient.handleCallWaiting: No active call - hanging up before ringing');
					if (previousCall) {
						sennheiser.hangup(previousCall.id);
					}
				}
				setTimeout(() => {
					const stillHasRingingCall = this.sessions.find(s => s.data.tcxState === 'CALL_RING');
					if (stillHasRingingCall) {
						sennheiser.inboundStart(ringingCall.id);
					}
				}, 500);
			}
		} else {
			logger.debug('SipClient.handleCallWaiting: No ringing calls - not gonna play a call waiting tone or ringing tone');
		}
	}

	/**
	 * Initialize an audio player for the remote audio
	 * @param {Object} session RTC session
	 * @param {Object} stream Stream containing the audio
	 */
	async createRemoteAudioPlayer(session, stream) {
		const id = 'SipPhoneRemoteAudio-' + Math.random().toString().substring(2);
		const parent = document.getElementById('SipPhoneVideoContainer');
		const audio = document.createElement('audio');
		audio.id = id;
		audio.style.display = 'none';
		audio.playsInline = true;
		parent.appendChild(audio);
		logger.debug('SipClient: Creating audio player for remote audio with id ' + id);

		if (browserDetect.isChrome && this.speakerDeviceId) {
			logger.debug(`SipClient: Chrome environment detected, setting speaker device to ${this.speakerDeviceId}`);
			audio.addEventListener('canplay', async () => {
				try { await audio.setSinkId(this.speakerDeviceId); }
				catch(err) { logger.error(`SipClient: Failed to set audio device to ${this.speakerDeviceId}`); }
			}, { once: true });
		}
		audio.srcObject = stream;
		audio.play();
		if (!session.data) { session.data = {}; }
		session.data.audioElement = audio;
	}

	/**
	 * Terminate an audio player for the remote audio
	 * @param {Object} session RTC session
	 */
	async destroyRemoteAudioPlayer(session) {
		if (!session.data?.audioElement) { return; }
		const parent = document.getElementById('SipPhoneVideoContainer');
		const ae = session.data.audioElement;
		ae.pause();
		ae.src = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
		parent.removeChild(session.data.audioElement);

		try {
			await session.data.audioElement.setSinkId('default');
			delete session.data.audioElement;
			setTimeout(() => { ae.pause(); ae.src = ''; }, 500);
			logger.info('SipClient: Destroying audio player for remote audio with id: ' + ae.id);
		} catch (err) {
			logger.error('SipClient: Error setting sinkId to default in destroyRemoteAudioPlayer - is ignored for now');
		}
	}

	/**
	 * Switch audio device currently used for call
	 */
	async switchAudioDevice() {
		if (browserDetect.isChrome) {
			logger.info(`SipClient: Switching audio device to: speaker ${this.speakerDeviceId} / microphone ${this.microphoneDeviceId}`);
		}

		for (let session of this.sessions) {
			if (browserDetect.isChrome && this.speakerDeviceId) {
				logger.debug(`SipClient: Setting speaker device to ${this.speakerDeviceId}`);
				await session.data.audioElement.setSinkId(this.speakerDeviceId);
			}

			if (this.microphoneDeviceId) {
				const pc = session.connection;
				const senders = pc.getSenders();
				const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.microphoneDeviceId }, video: false });
				try {
					await senders[0].replaceTrack(mediaStream.getTracks()[0]);
				}
				catch(err) {
					logger.info(`SipClient: Failed to replace audio input device for current call: ${err.message}`);
				}
			}
		}
	}

	parseUri(raw) {
		const res = { name: '', number: '' };
		const m = raw.match(/<sip:[^@:>;]+/);
		if (m) { res.number = m[0].substring(5); }
		res.name = raw.split('<')[0].trim().replace(/"/g, '');
		return res;
	}

	/**
	 * Lookup a session based on call-id or caller number or state
	 * @param {String} callId Call-ID of call
	 * @param {String} callerNumber Callers phone number
	 * @param {String} callState TCX Call State
	 * @returns {Object} RTC session
	 */
	getSession(callId, callerNumber, callState) {
		let session;
		if (callId) {
			// Find session using callId (I made the call)
			session = this.sessions.find(s => s.data.callId === callId);
			if (session) { return session; }
		}

		// Find call using callerNumber and callState - I received the call
		session = this.sessions.find(s => callerNumber.endsWith(s.data.caller.number) && s.data.tcxState === callState && s.data.direction === 'INBOUND');
		if (session) { return session; }

		// Check sipcall - in case we are looking for a call on hold and the sipcall does not know that it is on hold
		session = this.sessions.find(s => callerNumber.endsWith(s.data.caller.number) && callState === 'CALL_HOLD' && s.data.tcxState === 'CALL_ANSWER' && s.data.direction == 'INBOUND');
		if (session) { return this.sipcall; }

		// Find call using callState - I received the call
		session = this.sessions.find(s => s.data.tcxState === callState && s.data.direction === 'INBOUND');
		if (session) { return session; }

		return null;
	}

	getSessions() {
		return this.sessions;
	}

	/**
	 * Make a call
	 * 
	 */
	async call(number) {
		logger.info(`SipClient: Outbound call initated towards ${number} using ${this.server}`);
		const session = this.ua.call(
			`sip:${number}@${this.server.split('://')[1]}`,
			{
				pcConfig: {
					'iceServers': [ { urls: ['stun:mgw1.telecomx.dk:3478', 'stun:mgw2.telecomx.dk:3478' ] } ]
				},
				mediaConstraints: { audio: { deviceId: this.microphoneDeviceId }, video: false }
			}
		);

		session.on('progress', data => {
			if (data.response) {
				if (data.response.status_code === 183) {
					// Progress with audio
					session.data.tcxState = 'CALL_RING'; 
					this.stopRingTone();
					this.stopRingingTone();
					if (jabra.use) { jabra.offHook(); }
					if (yealink.use) { yealink.offHook(); }
					if (sennheiser.use) { sennheiser.outboundStart(session.id); }
				} else if (data.response.status_code == 180) {
					// Progress with ringing
					session.data.tcxState = 'CALL_RING';
					this.playRingingTone();
					if (jabra.use) { jabra.offHook(); }
					if (yealink.use) { yealink.offHook(); }
					if (sennheiser.use) { sennheiser.outboundStart(session.id); }
				}
			}
			session.data.tcxState = 'CALL_START';
			logger.info(`SipClient: Outbound call progress - ${data.response ? data.response.status_code : 'n/a'} ${data.response ? data.response.reason_phrase : 'n/a'}`);
		});

		session.on('failed', data => {
			switch (data.cause) {
				case JsSIP.C.causes.BUSY: this.onCallEnd(session, 'BUSY'); break;
				case JsSIP.C.causes.REJECTED: this.onCallEnd(session, 'REJECTED'); break;
				case JsSIP.C.causes.REDIRECTED: this.onCallEnd(session, 'REDIRECTED'); break;
				case JsSIP.C.causes.UNAVAILABLE: this.onCallEnd(session, 'UNREACHABLE'); break;
				case JsSIP.C.causes.NOT_FOUND: this.onCallEnd(session, 'NOT_FOUND'); break;
				case JsSIP.C.causes.CANCELED: this.onCallEnd(session, 'CANCELLED'); break;
				default:
					session.data.endCause = data.cause;
					this.onCallEnd(session, 'ERROR');
					break;
			}
		});
		session.on('accepted', data => { // 200 ok
			if (sennheiser.use && session.data.tcxState === 'CALL_START') { sennheiser.outboundStart(session.id); }
			session.data.tcxState = 'CALL_ANSWER';
			this.stopRingTone();
			this.stopRingingTone();
			if (jabra.use) { jabra.offHook(); }
			if (yealink.use) { yealink.offHook(); }
			if (sennheiser.use) { sennheiser.offHook(session.id); }
			kuando.busy();
			EventBus.$emit('SipClient', { event: 'ANSWERED', session: session });
			logger.info(`SipClient: Outbound call accepted - ${data.response ? data.response.status_code : 'n/a'} ${data.response ? data.response.reason_phrase : 'n/a'}`);
		});
		session.on('confirmed', data => { // ACK
			logger.debug(`SipClient: Outbound call confirmed (ACK sent) - ${data.originator}`);
		});
		session.on('ended', data => {
			switch (data.cause) {
				case JsSIP.C.causes.BYE: this.onCallEnd(session, 'BYE'); break;
				case JsSIP.C.causes.CANCELED: this.onCallEnd(session, 'CANCELLED'); break;
				case JsSIP.C.causes.NO_ANSWER: this.onCallEnd(session, 'NO_ANSWER'); break;
				case JsSIP.C.causes.EXPIRES: this.onCallEnd(session, 'NO_ANSWER'); break;
				default: session.data.endCause = data.cause; this.onCallEnd(session, 'ERROR'); break;
			}
		});
		session.on('icecandidate', event => {
			if (event.candidate.type === 'srflx' && event.candidate.relatedAddress !== null && event.candidate.relatedPort !== null) {
				logger.debug(`SipClient: ICE Candidate: ${JSON.stringify(event.candidate)}`);
				event.ready();
			}
		});
		session.on('hold', _ => { session.data.tcxState = 'CALL_HOLD'; EventBus.$emit('SipClient', { event: 'HOLD', session: session }); });
		session.on('unhold', _ => { session.data.tcxState = 'CALL_ANSWER'; EventBus.$emit('SipClient', { event: 'UNHOLD', session: session }); });
		session.on('muted', _ => { EventBus.$emit('SipClient', { event: 'MUTED', session: session }); }); // audio, video (bools)
		session.on('unmuted', _ => { EventBus.$emit('SipClient', { event: 'UNHOLD', session: session }); }); // audio, video (bools)

		if (!session.data) { session.data = {}; }
		session.data.direction = 'OUTBOUND'; // inbound, outbound
		session.data.originator = 'local'; // local, remote
		session.data.tcxState = 'CALL_START';
		session.data.caller = { name: this.extensionName, number: this.extensionNumber };
		session.data.callee = { name: '', number: number };
		this.sessions.push(session);
		kuando.calling();
		EventBus.$emit('SipClient', { event: 'OUTBOUND', session: session });
	}

	playRingTone(useCallWaitingRingTone, callerNumber) {
		logger.debug(`SipClient: Shall play ${useCallWaitingRingTone ? 'call waiting' : 'ring'} tone for caller ${callerNumber}`);
		this.shouldBePlayingRingtone = true;
		setTimeout(async () => {
			if (!this.shouldBePlayingRingtone) { return; }
			if ((s.ringtone && s.ringtone !== 'null' && s.ringtone != null) || useCallWaitingRingTone) { // We should still play useCallWaitingRingTone, even if the user has not chosen a ring tone
				logger.debug(`SipClient: Playing ${useCallWaitingRingTone ? 'call waiting' : 'ring'} tone`);

				let ae = document.getElementById('RingTonePlayer');
				if (!ae) {
					const parent = document.getElementById('SipPhoneVideoContainer');
					ae = document.createElement('audio');
					ae.id = 'RingTonePlayer';
					ae.autoplay = true;
					ae.loop = true;
					parent.appendChild(ae);
				}

				let ringtone = useCallWaitingRingTone ? CALL_WAITING_RINGTONE : s.ringtone;
				if (callerNumber.length < 8 && !useCallWaitingRingTone) { ringtone = s.ringtoneLocal; }
				if (useCallWaitingRingTone) {
					if (browserDetect.isChrome && this.speakerDeviceId) {
						ae.addEventListener('canplay', async () => {
							logger.debug(`SipClient: Setting ringtone device to ${this.speakerDeviceId}`);
							try { await ae.setSinkId(this.speakerDeviceId); }
							catch(err) { logger.error(`SipClient: Failed to set audio device to ${this.speakerDeviceId}`); }
						}, { once: true });
					}
				} else if (browserDetect.isChrome && this.ringtoneDeviceId) {
					ae.addEventListener('canplay', async () => {
						logger.debug(`SipClient: Setting ringtone device to ${this.ringtoneDeviceId}`);
						try { await ae.setSinkId(this.ringtoneDeviceId); }
						catch(err) { logger.error(`SipClient: Failed to set audio device to ${this.ringtoneDeviceId}`); }
					}, { once: true });
				}

				ae.src = `https://audio.telecomx.dk/${ringtone}.mp3`;
				ae.currentTime = 0;
				ae.volume = useCallWaitingRingTone ?  0.1 : mediaDevices.ringerVolume;
				ae.play();
			}
		}, 500);
	}

	stopRingTone() {
		this.shouldBePlayingRingtone = false;
		const ae = document.getElementById('RingTonePlayer');
		if (ae) {
			logger.info('SipClient: Stop playing ringtone');
			ae.pause();
			ae.loop = false;
			ae.autoplay = false;
			ae.src = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
			ae.parentNode.removeChild(ae);
			setTimeout(() => { ae.pause(); }, 500);
		}
	}

	async playRingingTone() {
		logger.info('SipClient: Playing ringing tone');
		let ae = document.getElementById('RingingTonePlayer');
		if (!ae) {
			const parent = document.getElementById('SipPhoneVideoContainer');
			ae = document.createElement('audio');
			ae.id = 'RingingTonePlayer';
			ae.autoplay = true;
			ae.loop = true;
			parent.appendChild(ae);
		}
		if (browserDetect.isChrome && this.speakerDeviceId) {
			ae.addEventListener('canplay', async () => {
				logger.debug(`SipClient: Setting ringingtone device to ${this.speakerDeviceId}`);
				try { await ae.setSinkId(this.speakerDeviceId); }
				catch(err) { logger.error(`SipClient: Failed to set audio device to ${this.speakerDeviceId}`); }
			}, { once: true });
		}

		ae.src = `https://audio.telecomx.dk/${RINGING_TONE}.mp3`;
		ae.currentTime = 0;
		ae.volume = 1;
		ae.play();
	}

	stopRingingTone() {
		const ae = document.getElementById('RingingTonePlayer');
		if (ae) {
			logger.info('SipClient: Stop playing ringingtone');
			ae.pause();
			ae.parentNode.removeChild(ae);
			setTimeout(() => { ae.pause(); }, 500);
		}
	}

	async playBusyTone() {
		logger.info('SipClient: Playing busy tone');
		let ae = document.getElementById('BusyTonePlayer');
		if (!ae) {
			const parent = document.getElementById('SipPhoneVideoContainer');
			ae = document.createElement('audio');
			ae.id = 'BusyTonePlayer';
			ae.autoplay = true;
			ae.loop = false;
			ae.addEventListener('ended', () => {
				if (jabra.use) { jabra.onHook(); }
				if (yealink.use) { yealink.onHook(); }
				if (sennheiser.use) { sennheiser.hangup(session.id); }
				kuando.idle();
			});
			parent.appendChild(ae);
		}
		if (this.speakerDeviceId) {
			await ae.setSinkId(this.speakerDeviceId);
		}
		ae.src = `https://audio.telecomx.dk/${BUSY_TONE}.mp3`;
		ae.currentTime = 0;
		ae.volume = 1;
		ae.play();
	}

	async playDtmfTone(tone) {
		logger.info('SipClient: Playing DTMF ' + tone);
		let ae = document.getElementById('DtmfTonePlayer');
		if (!ae) {
			const parent = document.getElementById('SipPhoneVideoContainer');
			ae = document.createElement('audio');
			ae.id = 'DtmfTonePlayer';
			ae.autoplay = true;
			ae.loop = false;
			parent.appendChild(ae);
		}
		if (browserDetect.isChrome && this.speakerDeviceId) {
			ae.addEventListener('canplay', async () => {
				logger.debug(`SipClient: Setting ringtone device to ${this.speakerDeviceId}`);
				try { await ae.setSinkId(this.speakerDeviceId); }
				catch(err) { logger.error(`SipClient: Failed to set audio device to ${this.speakerDeviceId}`); }
			}, { once: true });
		}

		ae.src = `https://audio.telecomx.dk/${DTMF_TONES[tone]}.mp3`;
		ae.currentTime = 0;
		ae.volume = 0.5;
		ae.play();
		setTimeout(() => { ae.pause(); }, 200);
	}

	async answer(session) {
		session.answer({
			mediaConstraints: { audio: { deviceId: this.microphoneDeviceId }, video: false }
		});
		logger.info('SipClient: Inbound call answered');

		const rtcPeerConnection = session.connection;
		if (!rtcPeerConnection) { logger.error('SipClient: rtcPeerConnection missing'); return; }
		rtcPeerConnection.addEventListener('track', async event => {
			logger.info(`SipClient: Track found for inbound call - kind: ${event.track.kind} - label: ${event.track.label} - enabled: ${event.track.enabled}`);
			this.createRemoteAudioPlayer(session, event.streams[0]);
		});

		this.stopRingTone();
		this.stopRingingTone();
		kuando.busy();
		if (jabra.use) { jabra.offHook(); }
		if (yealink.use) { yealink.offHook(); }
		if (sennheiser.use) { sennheiser.offHook(session.id); }
	}

	sendDTMF(key, session) {
		logger.info('SipClient: Sending DTMF ' + key);
		session.sendDTMF(key);
	}

	hold(session) {
		session.hold({}, () => {
			logger.info('SipClient: Hold start');
			if (jabra.use) { jabra.hold(); }
			if (sennheiser.use) { sennheiser.hold(session.id); }
			if (yealink.use) { yealink.hold(); }
		});
	}

	unhold(session) {
		session.unhold({}, () => {
			logger.info('SipClient: Hold end');
		});
		if (jabra.use) { jabra.unhold(); }
		if (sennheiser.use) { sennheiser.unhold(session.id); }
		if (yealink.use) { yealink.unhold(); }
		kuando.busy();
	}

	mute(session) {
		logger.info('SipClient: Mute start');
		session.mute({ audio: true }, () => { });
	}

	unmute(session) {
		logger.info('SipClient: Mute end');
		session.unmute({ audio: true }, () => { });
	}

	hangup(session) {
		logger.info('SipClient: Hangup initiated');
		try {
			session.terminate();
			logger.info('SipClient: Hangup completed');
		}
		catch(err) { logger.warning(`SipClient: Hangup failed - ${err.code} - ${err.message}`); }
	}

	decline(session) {
		logger.info('SipClient: Decline initiated');
		try {
			session.terminate();
			logger.info('SipClient: Decline completed');
		}
		catch(err) { logger.warning(`SipClient: Decline failed - ${err.code} - ${err.message}`); }
	}
	
	isCallActive(call) {
		if (this.sessions.length > 1) {
			return ['CALL_ANSWER', 'CALL_HOLD'].includes(call.data.tcxState);
		}
		return true; // If we only have one call, we assume that it is the only active call we have
	}
	
	headsetAnswerEvent() {
		logger.debug('SipClient: headsetAnswerEvent called');
		const relevantSessions = this.sessions.filter(o => ['CALL_RING', 'CALL_HOLD'].includes(o.data.tcxState));
		
		const call = relevantSessions[0];

		if (!call) {
			logger.debug(`SipClient: No relevant calls to answer from the ${relevantSessions.length} calls - exiting`);
			return;
		}

		if (call.data.tcxState === 'CALL_RING') {
			logger.debug(`SipClient: Only one call - answering ${call._id}`);
			EventBus.$emit('Call:Answer', { sessionId: call._id }); // There is only 1 call, just answer that one
			// return;
		} else {
			logger.debug(`SipClient: No suitable call to answer because it is in ${call.data.tcxState}, and there is only 1 call - exiting`);
			// return;
		}
	}

	headsetHangupEvent() {
		logger.debug('SipClient: headsetHangupEvent called');
		
		if (this.sessions.length > 1)  {
			logger.debug(`SipClient: There are ${this.sessions.length} calls, so we prioritize hanging up the outgoing ringing call -> answered call -> call on hold`);

			const outgoingRingingCall = this.sessions.find(o => o.data.tcxState === 'CALL_START');

			if (outgoingRingingCall) {
				logger.debug(`SipClient: There are ${this.sessions.length} calls, prioritizing outgoing ringing call - hanging up the call ${outgoingRingingCall._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: outgoingRingingCall._id });
				return;
			}

			const answeredCall = this.sessions.find(o => o.data.tcxState === 'CALL_ANSWER');

			if (answeredCall) {
				logger.debug(`SipClient: There are ${this.sessions.length} calls, no outgoing ringing call, but found an answered call - hanging up the call ${answeredCall._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: answeredCall._id });
				return;
			}

			const callOnHold = this.sessions.find(o => o.data.tcxState === 'CALL_HOLD');
			
			if (callOnHold) {
				logger.debug(`SipClient: There are ${this.sessions.length} calls, no outgoing ringing or answered call, but found a call on hold - hanging up the call ${callOnHold._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: callOnHold._id });
				return;
			}
		}

		const latestCall = this.sessions[this.sessions.length - 1];
			
		switch (latestCall?.data.tcxState) {
			case 'CALL_START':
				logger.debug(`SipClient: Cancelling our outgoing call ${latestCall._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: latestCall._id });
				return;
			case 'CALL_ANSWER':
				logger.debug(`SipClient: Hanging up the current answered call ${latestCall._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: latestCall._id });
				return;
			case 'CALL_RING':
				logger.debug(`SipClient: Hanging up the ringing call ${latestCall._id}`);
				EventBus.$emit('Call:Reject', { sessionId: latestCall._id });
				return;
			case 'CALL_HOLD':
				logger.debug(`SipClient: Hanging up the call on hold ${latestCall._id}`);
				EventBus.$emit('Call:Hangup', { sessionId: latestCall._id });
				return;
		}
	}
	headsetRejectEvent() {
		logger.debug('SipClient: headsetRejectEvent called');
		const relevantSessions = this.sessions.filter(o => ['CALL_START', 'CALL_RING', 'CALL_ANSWER'].includes(o.data.tcxState));

		if (relevantSessions.length === 1) {
			const activeCall = relevantSessions[0]._id;
			logger.debug(`SipClient: Rejecting only ringing call ${activeCall._id}`);
			EventBus.$emit('Call:Reject', { sessionId: activeCall._id }); // There is only 1 call, just answer that one
			return;
		}
		
		if (relevantSessions.length > 1) {
			const firstCall = relevantSessions[0]._id;
			logger.debug(`SipClient: There are ${relevantSessions.length} calls - rejecing first call ${firstCall}`);
			EventBus.$emit('Call:Reject', { sessionId: firstCall._id }); // There is only 1 call, just answer that one
			return;
		}
		
		// logger.debug(`SipClient: Fallback for rejecting calls - sending reject to all ${relevantSessions.length} calls`);
		// EventBus.$emit('Call:Reject');
	}
	headsetActivateHoldEvent() {
		logger.debug('SipClient: headsetActivateHoldEvent called');
		const activeCall = this.sessions.find(o => o.data.tcxState === 'CALL_ANSWER');
		
		if (activeCall) {
			EventBus.$emit('Call:ActivateHold', { sessionId: activeCall._id });
		}
	}
	headsetDeactivateHoldEvent() {
		logger.debug('SipClient: headsetDeactivateHoldEvent called');
		const callOnHold = this.sessions.find(o => o.data.tcxState === 'CALL_HOLD');
		
		if (callOnHold) {
			EventBus.$emit('Call:DeactivateHold', { sessionId: callOnHold._id });
		}
	}
	
}

// Singleton
export default new SipClient();

<template>
	<div class="top-bar" v-show="!hide" :class="{'impersonation':impersonated}">
		<div class="my-presence-box"><my-presence /></div>
		<div class="button-outer-container" style="flex-grow:5">
			<div class="button-container" v-if="!dashboards.getUseTopbar()">
				<topbar-button icon="158-thumbnails-small" :label="$t('topbar.buttons.dashboard')" :click="selectSection" id="DASHBOARD" :selected="s.selectedSection=='DASHBOARD'" />
				<topbar-button icon="89-book-address" :label="$t('topbar.buttons.phonebook')" :click="selectSection" id="PHONEBOOK" :selected="s.selectedSection=='PHONEBOOK'" />
				<topbar-button v-if="s.auth.recordingMode!='NEVER'&&!impersonated" icon="664-equalizer-bars" :label="$t('topbar.buttons.recordings')" :click="selectSection" id="RECORDINGS" :selected="s.selectedSection=='RECORDINGS'" :recording="recordingState.isRecording" />
				<topbar-button v-if="!impersonated" icon="662-voicemail" :label="$t('topbar.buttons.voicemail')" :click="selectSection" id="VOICEMAIL" :selected="s.selectedSection=='VOICEMAIL'" :badge="voicemailBadge" />
				<topbar-button icon="115-list" :label="$t('topbar.buttons.calls')" :click="selectSection" id="CALLS" :selected="s.selectedSection=='CALLS'" :badge-nummeric="callHistory.missed" />
			</div>
			<div class="button-container" v-if="dashboards.getUseTopbar()">
				<topbar-button v-if="s.selectedSection=='DASHBOARD'" icon="158-thumbnails-small" :label="dashboards.dashboard.name" :click="selectSection" id="DASHBOARD" :selected="s.selectedSection=='DASHBOARD'" />
				<topbar-button v-for="item in dashboards.getLabelsForTopbar()" v-bind:key="item._id" icon="158-thumbnails-small" :label="item.label" :click="selectSection" :id="'DASHBOARD-' + item._id" :selected="s.selectedSection=='DASHBOARD-'+item._id" />
				<topbar-button icon="89-book-address" :label="$t('topbar.buttons.phonebook')" :click="selectSection" id="PHONEBOOK" :selected="s.selectedSection=='PHONEBOOK'" />
				<topbar-button v-if="s.auth.recordingMode!='NEVER'&&!impersonated" icon="664-equalizer-bars" :label="$t('topbar.buttons.recordings')" :click="selectSection" id="RECORDINGS" :selected="s.selectedSection=='RECORDINGS'" :recording="recordingState.isRecording" />
				<topbar-button v-if="!impersonated" icon="662-voicemail" :label="$t('topbar.buttons.voicemail')" :click="selectSection" id="VOICEMAIL" :selected="s.selectedSection=='VOICEMAIL'" :badge="voicemailBadge" />
				<topbar-button icon="115-list" :label="$t('topbar.buttons.calls')" :click="selectSection" id="CALLS" :selected="s.selectedSection=='CALLS'" :badge-nummeric="callHistory.missed" />
			</div>
		</div>
		<div class="mainsearch" style="flex-grow:0;flex-shrink:0">
			<div class="phonedevice dotdotdot" @click="pacmanClick2">
				<glyph-icon icon="464-phone-old" size="14" color="var(--txt-color-secondary)" style="flex-grow:0;flex-shrink:0;margin-top:-2px" />
				<div style="flex-grow:0;flex-shrink:0;padding-left:1px;white-space: nowrap" :class="{'softphone-not-connected':softphoneNotConnected}">{{ myPhoneName }}</div>

				<glyph-icon id="topbarAudioInDevice" @click="audioInDeviceContextMenu" v-if="s.myPhoneId=='SOFTPHONE'" :icon="jabra.use||sennheiser.use||yealink.use?'78-headset':'341-mic'" size="14" color="var(--txt-color-secondary)" style="flex-grow:0;flex-shrink:0;margin-left:10px;cursor:pointer;margin-top:-2px" />
				<div @click="audioInDeviceContextMenu" v-if="s.myPhoneId=='SOFTPHONE'" style="flex-grow:0;padding-left:1px;white-space: nowrap;cursor:pointer">{{ jabra.use ? jabra.useDeviceName : (sennheiser.use ? sennheiser.useDeviceName : microphoneDeviceName) }}</div>
				<v-contextmenu v-if="s.myPhoneId=='SOFTPHONE'" ref="ContextMenuAudioDeviceSelection">
					<v-contextmenu-item v-for="item in mediaDevices.audioInDevices" @click="audioInDeviceSelect(item)" v-bind:key="item.value">
						<div style="display:flex">
							<glyph-icon v-if="item.selected" :icon="'739-check'" size="14" color="var(--txt-color-primary)" style="padding-right:3px" />
							<div v-if="!item.selected" style="width:17px">&nbsp;</div>
							{{ item.text }}
						</div>
					</v-contextmenu-item>
				</v-contextmenu>

			</div>


			<div @click="pacmanClick1" class="search-helper">{{ $t('topbar.searchtip') }}</div>
			<b-form-input type="search" id="MainSearchInput" size="sm" class="search-field" :class="{'search-field-extra':showSearchResults,'search-field-no-match':!showSearchResults&&searchFilter}" :placeholder="$t('topbar.searchPlaceholder')" v-model="searchFilter" @keydown.native="keycheck" @input="search"></b-form-input>
			<div class="search-results-box box-shadow2" v-show="showSearchResults" id="SearchResultBox">
				<div :id="'searchResultItem' + m.index" v-for="m in searchResults" :key="m._id" @click="searchSelect(m, $event)" class="search-result-item" :class="{'selected':m.selected}">
					<div v-if="m.type!='DIRECT'&&m.type!='TRANSFER'&&m.type!='INVITE'">
						<div class="result-item-employee" v-if="m.type=='EMPLOYEE'">
							<employee-picture :picture="m.item.picture" :size="36" :spinner="true"></employee-picture>
							<presence-icon :state="m.item.presence.state" :size="15" class="presence-state" />
							<div class="employee-oncall" :class="{'active': m.type=='EMPLOYEE'&&m.item.extensionsState!='IDLE'}">
								<glyph-icon icon="465-call" color="#fff" size="14" style="margin-top:-7px" />
							</div>

						</div>
						<div class="result-item-icon" style="padding-top:5px;padding-left:2px" v-if="m.type=='PHONEBOOK'">
							<glyph-icon icon="89-book-address" size="27" color="var(--pn-primary-1)"></glyph-icon>
						</div>
						<div class="result-item-icon" v-if="m.type=='SHORTCUT'">
							<glyph-icon icon="417-globe" size="35" color="var(--pn-primary-1)"></glyph-icon>
						</div>
						<div class="headline" v-html="m.label"></div>
						<div class="subline">
							<b-badge pill variant="secondary" v-for="field in m.fields" :key="field" style="margin-right:2px" v-html="field"></b-badge>
						</div>
					</div>
					<div v-if="m.type=='DIRECT'" style="display:flex;align-items:center">
						<div class="result-item-icon"><glyph-icon icon="465-call" size="34" color="var(--pn-primary-1)"></glyph-icon></div>
						<div class="headline">{{ $t('topbar.searchCall') }} {{ m.item | cutNationalPrefixAndFormat }}</div>
					</div>
					<div v-if="m.type=='TRANSFER'" style="display:flex;align-items:center">
						<div class="result-item-icon"><glyph-icon icon="886-step-forward" size="34" color="var(--pn-primary-1)"></glyph-icon></div>
						<div class="headline">{{ $t('topbar.searchTransferTo') }} {{ m.item | cutNationalPrefixAndFormat }}</div>
					</div>
					<div v-if="m.type=='INVITE'" style="display:flex;align-items:center">
						<div class="result-item-icon"><glyph-icon icon="7-user-plus" size="34" color="var(--pn-primary-1)"></glyph-icon></div>
						<div class="headline">{{ $t('topbar.searchInvite').replace('%%number%%', s.cutNationalPrefix(m.item)) }}</div>
					</div>
				</div>
			</div>
			<v-contextmenu ref="ContextMenuSearchSelectExtension" @hide="clearSearch">
				<v-contextmenu-item v-for="item in selectExtensionContextMenu" @click="call(item.call)" v-bind:key="item.id">{{ item.label }}</v-contextmenu-item>
			</v-contextmenu>
			<v-contextmenu ref="ContextMenuSearchSelectPhonebookNumber" @hide="clearSearch">
				<v-contextmenu-item v-for="item in selectPhonebookNumberContextMenu" @click="call(item.call)" v-bind:key="item.id">{{ item.label }}</v-contextmenu-item>
			</v-contextmenu>

		</div>
	</div>
</template>

<script>
import EventBus from '../../data/EventBus';
import TopbarButton from './TopbarButton.vue';
import GlyphIcon from '../GlyphIcon.vue';
import MyPresence from './MyPresence.vue';
import employees from '../../data/employees';
import phonebook from '../../data/phonebook';
import dashboards from '../../data/dashboards';
import u from '../../utils/utils';
import EmployeePicture from '../EmployeePicture.vue';
import s from '../../settings';
import PresenceIcon from '../PresenceIcon.vue';
import recordingState from '../../data/recordingState';
import sipClient from '../../data/sipClient';
import mediaDevices from '../../data/mediaDevices';
import jabra from '../../utils/headsets/jabra';
import sennheiser from '../../utils/headsets/sennheiser';
import yealink from '../../utils/headsets/yealink';
import i18n from '../../utils/i18n';
import callHistory from '../../data/callHistory';
import logger from '../../data/logger';

export default {
	components: {
		TopbarButton,
		MyPresence,
		EmployeePicture,
		GlyphIcon,
		PresenceIcon
	},

	data() {
		return {
			$t: i18n.t,
			callHistory: callHistory,
			s: s,
			title: '',
			searchFilter: '',
			hide: false, // for hiding topbar when in wallboard mode
			searchResults: [],
			showSearchResults: false,
			selectExtensionContextMenu: null,
			selectPhonebookNumberContextMenu: null,
			voicemailBadge: '',
			recordingState: recordingState,
			employee: null,
			jabra: jabra,
			sennheiser: sennheiser,
			yealink: yealink,
			microphoneDeviceName: null,
			sipClient: sipClient,
			mediaDevices: mediaDevices,
			pacmanCounter: 0,
			dashboards: dashboards
		};
	},
	methods: {
		pacmanClick1() {
			if (this.pacmanCounter == 0 || this.pacmanCounter == 2) {
				this.pacmanCounter++;
			} else {
				this.pacmanCounter = 0;
			}
		},
		pacmanClick2() {
			if (this.pacmanCounter == 1) {
				this.pacmanCounter++;
			} else if (this.pacmanCounter == 3) {
				this.pacmanCounter = 0;
				this.pacman();
			} else {
				this.pacmanCounter = 0;
			}
		},
		pacman() {
			const iframe = document.createElement('iframe');
			iframe.allow = 'autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write';
			iframe.sandbox = 'allow-scripts allow-presentation allow-same-origin';
			iframe.allowFullscreen = true;
			
			iframe.src = 'https://pacman.telecomx.dk';
			iframe.style.position = 'absolute';
			iframe.style.left = '50%';
			iframe.style.top = '50%';
			iframe.style.transform = 'translate(-50%, -50%)';
			iframe.style.zIndex = 1100;
			iframe.width = '1200px';
			iframe.height = '1000px';
			document.body.appendChild(iframe);
			iframe.style.opacity = 0.7;

			const cover = document.createElement('div');
			cover.style.position = 'absolute';
			cover.style.left = 0;
			cover.style.right = 0;
			cover.style.top = 0;
			cover.style.bottom = 0;
			cover.style.backgroundColor = '#555555B0';
			cover.style.zIndex = 1099;
			cover.style.cursor = 'pointer';
			cover.onclick = ev => {
				document.body.removeChild(iframe);
				document.body.removeChild(cover);
				document.body.style.overflow = 'scroll';
			};
			document.body.appendChild(cover);

			document.body.style.overflow = 'hidden';

		},
		selectSection(section) {
			if (section.startsWith('DASHBOARD-')) {
				this.dashboards.select(section.substr(10));
			}
			s.setSelectedSection(section);
		},
		search(query) {
			if (query == '' || query == '.') {
				this.clearSearch();
				return;
			}

			// . = only search employees
			let onlyEmployees = false;
			if (query.startsWith('.')) {
				onlyEmployees = true;
				query = query.substr(1);
			}

			let matches = [];
			const rawQuery = query.trim().replace('\u202c','').replace('\u202d','').toLowerCase();
			query = query.trim().replace('\u202c','').replace('\u202d','').toLowerCase().replace(/ {2}/g,' ').replace(/\./g, '\\.').replace(/\*/g, '\\*').replace(/-/g, '\\-').replace(/[[\]|(),$^{}\t]/g, '').replace(/\+/g, '\\+');
			if (query.replace(/ /g, '').replace(/-/g, '').match(/^\+?\d+$/) != null) {
				query = query.replace(/ /g, '').replace(/-/g, '');
			}
			const rxIs = new RegExp('(^|[ \n\r\t.,\'"+!?-]+)' + query + '([ \n\r\t.,\'"+!?-]+|$)'); // Match a word/sentence which is the query
			const rxBeginsWith = new RegExp('(^|[ \n\r\t.,\'"+!?-]+)' + query); // Match if a word/sentence begins with the query
			const rxContains = new RegExp(query); // Match if contains query
			const rxWords = new RegExp('^(?=.*' + query.replace(' ', ')(?=.*') + ')'); // Match if all words could be found in string
			const scoreExactMatch = 500;
			const scoreMatch = 100;
			const scoreStartsWith = 70;
			const scoreContains = 40;
			function compare(against, penalty, fields) {
				if (!against) { return 0; }
				if (fields.includes(against)) { return 0; }
				const original = against;
				against = against.toLowerCase().trim();
				let score = 0;
				if (rxIs.test(against)) { score += scoreMatch - penalty; fields.push(original); }
				else if (rxWords.test(against)) { score += scoreMatch - penalty; fields.push(original); }
				else if (rxBeginsWith.test(against)) { score += scoreStartsWith - penalty; fields.push(original); }
				else if (rxContains.test(against)) { score += scoreContains - penalty; fields.push(original); }
				return score;
			}

			// Search employees
			employees.employees.forEach(emp => {
				let score = 0;
				let fields = [];
				if (emp.name && emp.name.toLowerCase() == rawQuery.toLowerCase()) {
					score += scoreExactMatch;
				} else {
					score += compare(emp.name, 0, fields);
				}
				if (emp.title) { score += compare(emp.title, 30, fields); }
				if (emp.department) { score += compare(emp.department, 30, fields); }
				if (emp.address) {
					const address = `${emp.address.address}, ${emp.address.zip} ${emp.address.city}`.toLowerCase();
					score += compare(address, 20, fields);
				}

				if (emp.extensions && emp.extensions.length > 0) {
					emp.extensions.forEach(ext => {
						if (ext.number == rawQuery) { score += scoreExactMatch; fields.push(ext.number); }
						else if (ext.number && ext.number.startsWith(rawQuery)) { score += scoreStartsWith; fields.push(ext.number); }
						
						if (ext.direct) {
							if (ext.direct.replace(s.pbxSettings.nationalPrefix, '') == rawQuery || ext.direct == rawQuery) { score += scoreExactMatch; fields.push(ext.direct); }
							else if (ext.direct.replace(s.pbxSettings.nationalPrefix, '').startsWith(rawQuery) || ext.direct.startsWith(rawQuery)) { score += scoreStartsWith; fields.push(ext.direct); }
						}

						if (ext.mobile && ext.mobile.length > 0) {
							ext.mobile.forEach(mobile => {
								if (mobile.replace(s.pbxSettings.nationalPrefix, '') == rawQuery || mobile == rawQuery) { score += scoreExactMatch; fields.push(mobile); }
								else if (mobile.replace(s.pbxSettings.nationalPrefix, '').startsWith(rawQuery) || mobile.startsWith(rawQuery)) { score += scoreStartsWith; fields.push(mobile); }
							});
						}

					});
				}

				if (score > 0) {
					matches.push({ score: score, type: 'EMPLOYEE', item: emp, label: emp.name, fields: fields, selected: false, _id: emp._id });
				}
			});

			if (!onlyEmployees) {
				// Search phonebook
				phonebook.contacts.forEach(c => {
					let score = 0;
					let fields = [];
					const name = [ c.givenName || '', c.familyName || ''].join(' ').trim();
					if (name) {
						if (name.toLowerCase() == rawQuery.toLowerCase()) { score += scoreExactMatch; }
						else { score += compare(name, 10, fields); }
					}
					if (c.company) { score += compare(c.company, 10, fields); }
					if (c.title) { score += compare(c.title, 30, fields); }
					if (c.department) { score += compare(c.department, 30, fields); }
					if (c.addresses && c.addresses.length > 0) {
						c.addresses.forEach(addr => {
							const address = `${addr.street || ''}, ${addr.zip || ''} ${addr.city || ''}`.toLowerCase().trim();
							if (address) { score += compare(address, 20, fields); }
						});
					}
					if (c.phoneNumbers && c.phoneNumbers.length > 0) {
						c.phoneNumbers.forEach(pn => {
							if (pn.number && pn.number.replace(s.pbxSettings.nationalPrefix, '') == rawQuery || pn.number == rawQuery) {
								if (!fields.includes(pn.number)) {
									score += scoreExactMatch;
									fields.push(pn.number);
								}
							}
							else if (pn.number && pn.number.replace(s.pbxSettings.nationalPrefix, '').startsWith(rawQuery) || pn.number.startsWith(rawQuery)) {
								if (!fields.includes(pn.number)) {
									score += scoreStartsWith;
									fields.push(pn.number);
								}
							}
						});
					}
					if (c.emailAddresses && c.emailAddresses.length > 0) {
						c.emailAddresses.forEach(ea => {
							score += compare(ea.address, 30, fields);
						});
					}
					if (c.notes) { score += compare(c.notes, 30, fields); }
					if (c.shortNumber && c.shortNumber == rawQuery) {
						score += scoreExactMatch;
						fields.push(c.shortNumber);
					}

					if (score > 0) {
						if (fields.length == 0 || (fields.length == 1 && fields[0] == [c.givenName || '', c.familyName || ''].join(' ').trim()) || (fields.length == 1 && fields[0] == c.company)) {
							if (c.phoneNumbers.length > 0) {
								fields.push(c.phoneNumbers[0].number);
							}
							if (c.addresses.length > 0) {
								const addr = c.addresses[0];
								fields.push(`${addr.street || ''}, ${addr.zip || ''} ${addr.city || ''}`.toLowerCase().trim());
							}
						}
						matches.push({ score: score, type: 'PHONEBOOK', item: c, label: c.type =='COMPANY' ? c.company : [c.givenName,c.familyName].join(' ') + (c.company ? ` - ${c.company}` : ''), fields: fields, selected: false, _id: c._id });
					}
				});

				// Search shortcuts
				if (dashboards.dashboard) {
					dashboards.dashboard.items.forEach(panel => {
						if (panel.type == 'SHORTCUTS') {
							panel.shortcuts.forEach(sc => {
								let score = 0;
								let fields = [];
								score += compare(sc.name, 20, fields);
								if (sc.number.replace(s.pbxSettings.nationalPrefix, '') == rawQuery || sc.number == rawQuery) { score += scoreMatch - 20; fields.push(sc.number); }
								else if (sc.number.replace(s.pbxSettings.nationalPrefix, '').startsWith(rawQuery) || sc.number.startsWith(rawQuery)) { score += scoreStartsWith - 20; fields.push(sc.number); }

								if (score > 0) {
									matches.push({ score: score, type: 'SHORTCUT', item: sc, label: sc.name, fields: fields, selected: false, _id: u.randomString(20) });
								}
							});
						}
					});
				}

				// TODO: Search employee-less/not in dashboard extensions
			}

			// sort by score and grab top 15
			matches.sort(u.dynamicSortMultiple('-score', 'label'));
			matches = matches.slice(0, 15);

			// Add number if input is a phone number that can be called
			const nQuery = rawQuery.replace(/-/g, '').replace(/ /g, '');
			if (nQuery.match(/^(\+?\d+|[*#0-9]+)$/)) {
				const cc = this.employee.currentCall;
				if (cc && cc.state == 'CALL_ANSWER' && s.cutNationalPrefix(cc.caller.number) != s.cutNationalPrefix(nQuery) && s.cutNationalPrefix(cc.callee.number) != s.cutNationalPrefix(nQuery)) {
					matches.unshift({ score: 0, type: 'INVITE', item: nQuery, label: rawQuery, fields: [ rawQuery ], selected: false, _id: u.randomString(20) });
				}
				if (cc) {
					matches.unshift({ score: 0, type: 'TRANSFER', item: nQuery, label: rawQuery, fields: [ rawQuery ], selected: false, _id: u.randomString(20) });
				}
				matches.unshift({ score: 0, type: 'DIRECT', item: nQuery, label: rawQuery, fields: [ rawQuery ], selected: false, _id: u.randomString(20) });
			}

			if (matches.length == 0) {
				this.clearSearch(true);
			} else {
				matches[0].selected = true;
				matches.forEach((m, index) => {
					m.index = index;
					m.fields = m.fields.filter(f => f != m.label).map(o => this.highlight(s.cutNationalPrefix(o, true), query));
					m.fields = u.getUnique(m.fields);
					m.label = this.highlight(s.cutNationalPrefix(m.label, true), query);
				});
				this.searchResults = matches;
				this.showSearchResults = true;
				this.removeClickListener = this.hideOnClickOutside(document.getElementById('SearchResultBox'));
			}
		},
		highlight(txt, hl) {
			const lcTxt = txt.toLowerCase();
			const hlBits = hl.toLowerCase().split(/ +/);
			const hls = [];
			hlBits.forEach(hlBit => {
				for (let i=0; i<txt.length; i++) {
					if (lcTxt.substr(i, hlBit.length) == hlBit) {
						hls.push({ from: i, to: i + hlBit.length - 1});
						i += hlBit.length;
					}
				}
			});
			let out = '';
			for (let i=0; i<txt.length; i++) {
				if (hls.find(o => o.from == i)) {
					out += '<b>';
				}
				out += txt[i];
				if (hls.find(o => o.to == i)) {
					out += '</b>';
				}
			}
			return out;
		},
		clearSearch(notFilter) {
			this.showSearchResults = false;
			this.contextMenu = false;
			if (!notFilter) {
				this.searchFilter = '';
			}
			this.searchResults = [];
			if (this.removeClickListener) {
				this.removeClickListener();
				this.removeClickListener = null;
			}
		},
		searchSelect(item) {
			this.searchResults.forEach(sr => { sr.selected = sr.index == item.index; });
			if (item.type == 'DIRECT') {
				this.clearSearch();
				document.activeElement.blur();
				this.call(item.item);
			} else if (item.type == 'TRANSFER') {
				this.clearSearch();
				document.activeElement.blur();
				this.transferTo(item.item);
			} else if (item.type == 'INVITE') {
				this.clearSearch();
				document.activeElement.blur();
				this.invite(item.item);
			} else if (item.type == 'EMPLOYEE') {
				if (item.item.extensions.length > 1) {
					this.searchSelectExtension(item.item, item.index);
				} else {
					this.clearSearch();
					document.activeElement.blur();
					const ext = item.item.extensions[0];
					if (ext.number) { this.call(ext.number); }
					else if (ext.direct) { this.call(s.cutNationalPrefix(ext.direct)); }
					else if (ext.mobile && ext.mobile.length > 0) { this.call(s.cutNationalPrefix(ext.mobile[0])); }
					else { this.call(ext._id); }
				}
			} else if (item.type == 'PHONEBOOK') {
				if (item.item.phoneNumbers.length > 1) {
					this.searchSelectPhonebookNumber(item.item, item.index);
				} else {
					this.clearSearch();
					document.activeElement.blur();
					this.call(item.item.phoneNumbers[0].number);
				}
			} else if (item.type == 'SHORTCUT') {
				this.clearSearch();
				document.activeElement.blur();
				this.call(item.item.number);
			}
		},
		async searchSelectExtension(item, index) {
			this.selectExtensionContextMenu = [];
			item.extensions.forEach(ext => {
				if (ext.number) { this.selectExtensionContextMenu.push({ label: i18n.t('topbar.searchCall') + ' ' + ext.number, call: ext.number, id: this.selectExtensionContextMenu.length }); }
				else if (ext.direct) { this.selectExtensionContextMenu.push({ label: i18n.t('topbar.searchCall') + ' ' + s.cutNationalPrefix(ext.direct), call: s.cutNationalPrefix(ext.direct), id: this.selectExtensionContextMenu.length }); }
				else if (ext.mobile && ext.mobile.length > 0) { this.selectExtensionContextMenu.push({ label: i18n.t('topbar.searchCall') + ' ' + s.cutNationalPrefix(ext.mobile[0]), call: s.cutNationalPrefix(ext.mobile[0]), id: this.selectExtensionContextMenu.length }); }
			});

			const obj = document.getElementById('searchResultItem' + index);
			const coords = this.GetScreenCordinates(obj);
			setTimeout(() => {
				this.$refs.ContextMenuSearchSelectExtension.show({ left: coords.x + 50, top: coords.y + 27 });
			},1);
		},
		GetScreenCordinates(obj) {
			let p = {};
			p.x = obj.offsetLeft;
			p.y = obj.offsetTop;
			while (obj.offsetParent) {
				p.x = p.x + obj.offsetParent.offsetLeft;
				p.y = p.y + obj.offsetParent.offsetTop;
				if (obj == document.getElementsByTagName('body')[0]) {
					break;
				}
				else {
					obj = obj.offsetParent;
				}
			}
			return p;
		},
		async audioInDeviceContextMenu() {
			const obj = document.getElementById('topbarAudioInDevice');
			const coords = this.GetScreenCordinates(obj);
			setTimeout(() => {
				this.$refs.ContextMenuAudioDeviceSelection.show({ left: coords.x + 0, top: coords.y + 20 });
			},1);

		},
		async audioInDeviceSelect(device) {
			// value, text, group
			const audioOutDevice = mediaDevices.audioOutDevices.find(o => o.group == device.group);
			if (audioOutDevice) {
				mediaDevices.speakerDeviceId = audioOutDevice.value;
			}
			mediaDevices.microphoneDeviceId = device.value;
			logger.info(`Topbar: User selected ${audioOutDevice?'microphone and speaker device':'microphone device'} ${device.text}`);
		},
		async searchSelectPhonebookNumber(item, index) {
			this.selectPhonebookNumberContextMenu = [];
			item.phoneNumbers.forEach(pn => {
				this.selectPhonebookNumberContextMenu.push({ label: i18n.t('topbar.searchCall') + ' ' + s.cutNationalPrefix(pn.number), call: pn.number, id: this.selectPhonebookNumberContextMenu.length });
			});
			const obj = document.getElementById('searchResultItem' + index);
			const coords = this.GetScreenCordinates(obj);
			setTimeout(() => {
				this.$refs.ContextMenuSearchSelectPhonebookNumber.show({ left: coords.x + 50, top: coords.y + 27 });
			},1);
		},
		async call(number) {
			if (!s.myPhoneId) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') });
				return;
			}
			logger.info(`Topbar: User is calling ${number} via search menu`);
			if (s.myPhoneId == 'SOFTPHONE') {
				EventBus.$emit('Call:ActivateHold');
				await s.sleep(100);
				sipClient.call(number);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(number)}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
					logger.error(`Topbar: User call to ${number} failed: ${err.message}`);
				});
			}
		},
		async transferTo(number) {
			// Blind transfer
			logger.info(`TopBar: User made a blind transfer of ${(myCall.leg == 'I' ? myCall.callee.number : myCall.caller.number)} to ${this.employee.name}`);
			EventBus.$emit('BlindTransferRequested', { source: this.employee.currentCall._id, target: number });
			s.http.get(`/pbx/app/action/transfer?source=${this.employee.currentCall._id}&target=${number}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.transferFailedHeader'), message: i18n.t('common.error.transferFailedMessage') });
				logger.error(`Topbar: User blind transfer to ${number} failed: ${err.message}`);
			});
		},
		async invite(number) {
			// Add to existing call
			logger.info(`TopBar: User is inviting ${number} to join existing call`);
			s.http.get(`/pbx/app/action/invite?source=${this.employee.currentCall._id}&target=${number}`)
				.catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.inviteFailedHeader'), message: i18n.t('common.error.inviteFailedMessage').replace('%%number%%', number) });
					logger.error(`Topbar: User invite ${number} into call failed: ${err.message}`);
				});
		},
		keycheck(e) {
			if (e.keyCode == 27) {
				e.preventDefault();
				this.clearSearch();
			}
			else if (e.keyCode == 13 && this.searchResults.length > 0 && !this.contextMenu) {
				e.preventDefault();
				const selected = this.searchResults.find(o => o.selected);
				if (selected) { this.searchSelect(selected); }
			} else if (e.keyCode == 38 && this.searchResults.length > 0) {
				e.preventDefault();
				const index = this.searchResults.findIndex(o => o.selected);
				if (index > 0 && this.searchResults[index-1]) {
					this.searchResults[index].selected = false;
					this.searchResults[index-1].selected = true;
				}
			} else if (e.keyCode == 40 && this.searchResults.length > 0) {
				e.preventDefault();
				const index = this.searchResults.findIndex(o => o.selected);
				if (this.searchResults[index+1]) {
					this.searchResults[index].selected = false;
					this.searchResults[index+1].selected = true;
				}
			}

		},
		hideOnClickOutside(element) {
			const this2 = this;
		    const outsideClickListener = event => {
				if (!element.contains(event.target)) {
					this2.clearSearch();
				}
			};

			const removeClickListener = () => {
				document.removeEventListener('click', outsideClickListener);
			};

			document.addEventListener('click', outsideClickListener);
			return removeClickListener;
		},
		async deviceIdToName(id) {
			await mediaDevices.ready();
			let device = mediaDevices.devices.find(o => o.deviceId == id);
			if (device) {
				return device.label;
			} else {
				return 'Standard';
			}
		}
	},
	computed: {
		impersonated: function() { return s.auth.impersonatedBy != null; },
		myPhoneName: function() {
			if (!s.myPhoneId) { return i18n.t('topbar.noPhoneSelected'); }
			if (s.myPhoneId == 'SOFTPHONE') { return i18n.t('topbar.softphone'); }
			if (this.employee) {
				// this.employee.primaryExtension.phones.forEach(x => {
				// 	console.log(`Found ${x._id} - ${x.model || 'mobil'}`);
				// });
				const phone = this.employee.primaryExtension.phones.find(o => o._id == s.myPhoneId);
				if (phone) {
					if (phone.name) {
						return `${s.cutNationalPrefix(phone.name)} (${phone.model || i18n.t('topbar.mobile') })`;
					} else {
						return phone.model || i18n.t('topbar.mobile');
					}
				}
				return i18n.t('topbar.unknown');
			} else {
				return i18n.t('topbar.unknownEmployee');
			}
		},
		softphoneNotConnected: function() {
			if (s.myPhoneId != 'SOFTPHONE') { return false; }
			if (sipClient.registered) { return false; }
			return true;
		}
	},
	created() {
		employees.getMe().then(emp => {
			this.employee = emp;
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
		}).catch(() => {});
		EventBus.$on('FocusSearch', () => {
			const el = document.getElementById('MainSearchInput');
			el.value = '';
			el.focus();
		});
		EventBus.$on('Layout:Wallboard', enabled => { this.hide = enabled; });
		EventBus.$on('Voicemail:Count', data => {
			if (data.newMessages > 0) {
				this.voicemailBadge = `${data.newMessages}`;
			} else {
				this.voicemailBadge = null;
			}
		});
		EventBus.$on('Auth:LoggedOut', () => {
			this.employee = null;
		});
		EventBus.$on('Auth:LoggedIn', () => {
			employees.getMe().then(emp => {
				this.employee = emp;
				if (!emp) {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
					s.logout();
					return;
				}
			}).catch(() => {});
		});
		if (s.myPhoneId == 'SOFTPHONE') {
			mediaDevices.ready()
				.then(() => this.deviceIdToName(mediaDevices.microphoneDeviceId))
				.then(val => { this.microphoneDeviceName = val; });
		}
		EventBus.$on('MediaDeviceSelected', data => {
			if (s.myPhoneId == 'SOFTPHONE' && data.type == 'audioinput') {
				mediaDevices.ready()
					.then(() => this.deviceIdToName(mediaDevices.microphoneDeviceId))
					.then(val => { this.microphoneDeviceName = val; });
			}
		});
		EventBus.$on('MediaDeviceChange', () => {
			if (s.myPhoneId == 'SOFTPHONE') {
				mediaDevices.ready()
					.then(() => this.deviceIdToName(mediaDevices.microphoneDeviceId))
					.then(val => { this.microphoneDeviceName = val; });
			}
		});
	}
};
</script>

<style scoped>
	.button-outer-container {
		height: 60px;
		overflow: hidden;
	}
	.button-container {
		display:flex;
		justify-content:space-around;
		overflow-x: auto;
		overflow-y: hidden;
	}
	@media (max-width: 849px) {
		.button-container {
			background-color: var(--topbar-small-background);
			border-radius: 10px;
			border: var(--topbar-small-border);
		}
		.my-presence-box {
			order: 1;
		}
	}
	.my-presence-box {
		flex-grow: 0;
		flex-shrink: 0;
		order: 1;
	}

	.top-bar {
		position: fixed;
		-webkit-user-select: none;
		user-select: none;
		top: 0;
		width: 100%;
		background: var(--topbar-background);
		box-shadow: var(--topbar-shadow);
		z-index: 100;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}
	@media (max-width:849px) {
		.top-bar {
			flex-direction: column;
			align-items: stretch;
		}
	}
	.mainsearch {
		transition: width 0.5s;
		order: 3;
	}
	.search-helper {
		margin-top: 0px;
		margin-right: 10px;
		margin-bottom: -2px;
		text-align: right;
		font-size: var(--font-size-small);
		color: var(--pn-primary-1);
		font-style: italic;
	}
	.search-field {
		border-radius: 14px;
		width: 300px;
		transition: width 0.5s;
	}
	@media (min-width:850px) and (max-width:1150px) {
		.search-field {
			width: 200px;
		}
	}
	@media (max-width: 849px) {
		.search-field {
			width: 300px;
			float: right;
		}
	}
	@media (max-width: 390px) {
		.search-field {
			width: 100%;
		}
	}
	.button-outer-container {
		order: 2;
	}
	@media(max-width:849px) {
		.button-outer-container {
			order: -1;
		}
	}
	.search-field-extra {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.search-field-no-match {
		background-color: var(--topbar-search-no-match-background) !important;
		border: var(--topbar-search-no-match-border) !important;
		color: var(--topbar-search-no-match-color) !important;
	}
	.search-results-box {
		position: absolute;
		width: 300px;
		background: var(--topbar-search-result-background);
		border: 1px solid var(--topbar-search-border);
		right: 9px;
		top: 68px;
		z-index: 100;
		text-align: left;
		overflow-y: scroll;
	}
	@media (max-width: 849px) {
		.search-results-box {
			top: 168px;
		}
	}
	.search-result-item {
		border-bottom: 1px solid var(--topbar-search-border);
		height: 42px;
		cursor: pointer;
		transition: 999ms;
	}
	.search-result-item:last-of-type {
		border-bottom: none;
	}
	.search-result-item.selected {
		background-color: var(--context-menu-highlight-background) !important;
		color: var(--txt-color-primary-highlight) !important;
		text-shadow: 1px 1px #00000020;
		transition: 200ms;
	}
	.search-result-item:nth-child(even) {
		background-color: var(--topbar-search-result-background-odd);
	}
	.search-result-item > DIV {
		padding: 2px 5px;
	}
	.result-item-employee {
		float: left;
		margin-right: 5px;
		display: flex;
	}
	.result-item-icon {
		float: left;
		margin-right: 5px;
		background: var(--topbar-search-result-icon-background);
		border-radius: 25px;
		margin-top: 1px;
		border: var(--topbar-search-result-icon-border);
		width: 36px;
		height: 36px;
	}
	.search-result-item > DIV > .headline {
		font-weight: 300;
		font-size: var(--font-size-secondary);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.search-result-item > DIV > .subline {
		margin-top:-2px;
		font-size: var(--font-size-secondary) !important;
		overflow: hidden;
		white-space: nowrap;
	}
	.search-result-item > DIV > .subline > .badge {
		font-weight: 300;
		background-color: var(--topbar-search-result-badge-background);
		border: var(--topbar-search-result-badge-border);
		color: var(--topbar-search-result-badge-color);
		text-shadow: none;
	}
	.search-result-item.selected > DIV > .subline > .badge {
		color: var(--topbar-search-result-badge-color-highlight);
		background-color: var(--topbar-search-result-badge-background-hightlight);
	}
	.presence-state {
		position: absolute;
		margin-top: 20px;
		margin-left: 23px;
		flex-shrink: 0;
	}
	.employee-oncall {
		position: absolute;
		margin-top: 0px;
		margin-left: 23px;
		flex-shrink: 0;
		border: 1px solid var(--presence-busy);
		border-radius: 50%;
		z-index: 1;
		width: 16px;
		height: 16px;
		background-color:var(--presence-busy);
		opacity: 0;
		transition: 1s;
	}
	.employee-oncall.active {
		opacity: 1;
	}
	.phonedevice {
		width: 300px;
		font-size: var(--font-size-small);
		display: flex;
		margin-bottom: 8px;
		color: var(--txt-color-secondary);
		transition: width 0.5s;
		justify-content: flex-start;
	}
	@media (min-width:850px) and (max-width:1150px) {
		.phonedevice {
			width: 200px;
		}
	}
	@media (max-width: 849px) {
		.phonedevice {
			width: 100%;
			background: var(--pn-primary-5);
			border-radius: 7px;
			padding-left:5px;
			padding-right: 5px;
		}
	}
	.softphone-not-connected {
		color: red !important;
	}
	.impersonation {
		background: repeating-linear-gradient(
			45deg,
			#fcf7f2,
			#fcf7f2 10px,
			#ece7e2 10px,
			#ece7e2 20px
			);
	}

</style>
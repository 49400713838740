/*
 * Dashboards (used by renderer)
 * 
 * - Holds the current dashboard
 * - Handles everything about dashboards
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2020-06-08
 */

import u from '../utils/utils';
import EventBus from './EventBus';
import s from '../settings';
import rt from './realtime';
import i18n from '../utils/i18n';
import logger from './logger';

class Dashboards {
	constructor() {
		this.dashboards = [];
		this.dashboard = null;
		this.mode = 'NORMAL'; // NORMAL, CREATE, EDIT, WALLBOARD
		this.previousDashboard = null;
		this.mayEdit = false;
		this.dragItem = null;
		this.dragItemWidth = null;
		this.dragItemHeight = null;
		this.dragTarget = null;
		this.ready = false;

		EventBus.$on('Realtime:Connected', this.init.bind(this));
		EventBus.$on('Auth:LoggedOut', () => {
			this.dashboards = [];
			this.dashboard = null;
			this.mode = 'NORMAL';
			this.previousDashboard = null;
			this.mayEdit = false;
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'dashboards', value: this.ready });
		});
		EventBus.$on('Dashboard:DeleteItem', this.emergencyDeleteItem.bind(this));

		if (rt.connected) {
			this.init();
		}

		logger.registerGlobal('dashboards', this);
	}

	async init() {
		await s.ready();
		await this.loadList();
		await this.getEditorPermission();
		await this.load();
		this.ready = true;
	}

	// #region Data - load/save/delete dashboards

	get editMode() { return this.mode != 'NORMAL' && this.mode != 'WALLBOARD'; }

	/**
	 * Load the list of available dashboards
	 * @returns {Promise}
	 */
	async loadList() {
		return s.http.get('/pbx/app/dashboard')
			.then(res => {
				res.data.sort(u.dynamicSort('name'));
				this.dashboards = res.data;
			});
	}

	/**
	 * Load the selected dashboard from server
	 * @returns {Promise}
	 */
	async load() {
		// If dashboard id is invalid set it to first available dashboard - if any exists
		if (s.myDashboardId && this.dashboards.find(o => o._id == s.myDashboardId && this.dashboards.length > 0) == null) {
			if (this.dashboards.length > 0) {
				s.setMyDashboardId(this.dashboards[0]._id);
			} else {
				s.setMyDashboardId(null);
			}
		}
		// If no dashboard has been selected, select the first available if any exists
		if (!s.myDashboardId && this.dashboards.length > 0) { s.setMyDashboardId(this.dashboards[0]._id); }

		if (s.myDashboardId) {
			return s.http.get(`/pbx/app/dashboard/${s.myDashboardId}`)
				.then(res => {
					if (res.data) {
						res.data.items.forEach(item => {
							if (!item.tag) {
								item.tag = u.randomString(64);
							}
						});
						this.dashboard = res.data;
						if (s.selectedSection === 'DASHBOARD' && this.dashboard.topbar > 0) {
							s.setSelectedSection('DASHBOARD-' + this.dashboard._id);
						} else if (s.selectedSection.startsWith('DASHBOARD-') && !this.dashboard.topbar) {
							s.setSelectedSection('DASHBOARD');
						}
					}
				})
				.catch(err => {
					if (err.status == 404) {
						this.dashboard = null;
						s.setMyDashboardId(null);
					}
				});
		}
	}

	/**
	 * This is inovoked by a queue if it is not able anymore to find the queue because nobody has access to it anymore
	 * - will remove the item, save it and reload the dashboard
	 * @param {Object} item Item to be deleted from the dashboard
	 */
	emergencyDeleteItem(item) {
		if (this.dashboard) {
			const index = this.dashboard.items.findIndex(o => o.column === item.column && o.position === item.position);
			if (index !== -1) {
				this.dashboard.items.splice(index, 1);
				this.save();
			}
		}
	}

	/**
	 * Save the current dashboard
	 * @returns {Promise}
	 */
	async save() {
		// Validate data
		let error;
		this.dashboard.items.forEach(item => {
			const recol = str => { return str.replace('%%column%%', (item.column + 1).toString()); };
			if (item.type == 'EXTENSIONS') {
				if (!item.title) { error = i18n.t('dashboards.coworkerPanelWithoutName').recol(); }
				if (item.extensions.length == 0) { error = i18n.t('dashboards.coworkerPanelHasNoContent').recol(); }
			} else if (item.type == 'QUEUE') {
				if (!item._id) { error = i18n.t('dashboards.queuePanelWithQueue').recol(); }
				if (!item.variable) { item.variableName = ''; }
			} else if (item.type == 'WEB') {
				if (item.width < 0 || item.width > 2000) { error = i18n.t('dashboards.webPanelHasInvalidWidth').recol(); }
				if (item.height < 0 || item.height > 2000) { error = i18n.t('dashboards.webPanelHasInvalidHeight').recol(); }
				if (!item.title) { error = i18n.t('dashboards.webPanelWithoutName').recol(); }
				if (!item.url) { error = i18n.t('dashboards.webPanelWithoutUrl').recol(); }
			} else if (item.type == 'SHORTCUTS') {
				if (!item.title) { error = i18n.t('dashboards.shortcutsPanelWithoutName').recol(); }
				if (!item.shortcuts.every(o => o.name && o.number)) { error = i18n.t('dashboards.shortcutsPanelInvalidShortcuts').recol(); }
			} else if (item.type == 'SWITCHVAR') {
				if (!item.title) { error = i18n.t('dashboards.switchvarPanelWithoutName').recol(); }
				if (!item.variables.every(o => o.name && o.label)) { error = i18n.t('dashboards.switchvarPanelInvalidSwitches'); }
			}
		});
		if (error) {
			throw new Error(i18n.t('dashboards.panelHasError') + ' ' + error);
		}

		if (!this.dashboard._id) { // Create
			return s.http.post('/pbx/app/dashboard', this.dashboard)
				.then(res => {
					res.data.items.forEach(item => {
						if (!item.tag) {
							item.tag = u.randomString(64);
						}
					});
					this.dashboard = res.data;
				})
				.then(() => { this.mode = 'NORMAL'; })
				.then(() => { s.setMyDashboardId(this.dashboard._id); })
				.then(() => this.loadList())
				.catch(err => { EventBus.$emit('CommonErrorModal', { header: i18n.t('dashboards.unexpectedErrorHeader'), message: i18n.t('dashboards.unexpectedErrorMessage') + ' ' + err.message }); });
		} else { // Update
			return s.http.post(`/pbx/app/dashboard/${this.dashboard._id}`, this.dashboard)
				.then(res => {
					res.data.items.forEach(item => {
						if (!item.tag) {
							item.tag = u.randomString(64);
						}
					});
					this.dashboard = res.data;
				})
				.then(() => { this.mode = 'NORMAL'; })
				.then(() => this.loadList())
				.catch(err => { EventBus.$emit('CommonErrorModal', { header: i18n.t('dashboards.unexpectedUpdateErrorHeader'), message: i18n.t('dashboards.unexpectedUpdateErrorMessage') + ' ' + err.message }); });
		}
	}

	/**
	 * Delete a dashboard - and selects another if any
	 * @param {String} _id Id of the dashboard
	 * @returns {Promise}
	 */
	remove(_id) {
		const name = this.dashboard.name;
		s.http.delete(`/pbx/app/dashboard/${_id}`)
			.catch(err => {
				if (err.status != 404) { throw err; }
			})
			.then(() => { this.mode = 'NORMAL'; })
			.then(() => this.loadList())
			.then(() => {
				if (this.dashboards.length > 0) {
					return this.select(this.dashboards[0]._id);
				} else {
					this.dashboard = null;
				}
			})
			.then(() => {
				const post = this.dashboard ? i18n.t('dashboards.deletedAndOtherSelected').replace('%%name%%', this.dashboard.name) : '.';
				const n = new Notification(i18n.t('dashboards.deletedHeader'), {
					body: `'${name} ${i18n.t('dashboards.deletedMessage')}${post}`
				});
			})
			.catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('dashboards.deleteFailedHeader'), message: i18n.t('dashboards.deleteFailedMessage') + ' ' + err.message });
			});
	}

	/**
	 * Get settings showing if the current user may edit dashboards or not
	 */
	async getEditorPermission() {
		this.mayEdit = s.isDashboardEditor;
	}

	// #endregion

	// #region Mode Dashboard actions - select, create, edit, cancel

	/**
	 * Select a dashboard, and have it loaded
	 * @param {String} id Id of dashboard
	 */
	async select(id) {
		s.setMyDashboardId(id);
		this.load();
	}

	/**
	 * Start creating a new dashboard
	 */
	async create() {
		if (this.dashboard) { this.previousDashboard = this.dashboard; }
		this.dashboard = {
			_id: null,
			customer: s.auth.customer,
			name: '',
			topbar: 0,
			items: []
		};
		this.mode = 'CREATE';
	}

	/**
	 * Start editing the current dashboard
	 */
	edit() {
		if (this.dashboard) { this.previousDashboard = u.extendObject({}, this.dashboard); }
		this.mode = 'EDIT';
	}

	/**
	 * Cancel create or edit mode, and return to previous dashboard if any
	 */
	cancel() {
		this.dashboard = this.previousDashboard;
		this.previousDashboard = null;
		this.mode = 'NORMAL';
	}

	// #endregion

	// #region Columns

	/**
	 * Get the number of columns
	 * @returns {Number} Nunmber of columns
	 */
	getColumnCount() {
		if (!this.dashboard) { return 0; }
		if (this.dashboard.items.length == 0) { return 1; }

		const data = {};
		this.dashboard.items.forEach(item => {
			data[item.column] ? data[item.column]++ : data[item.column] = 1;
		});
		return Object.keys(data).length;
	}

	/**
	 * Return an array of column indexes - for containers layout of columns
	 * @returns {Array<Number>}
	 */
	getColumnIndexes() {
		const cols = this.getColumnCount();
		const list = [];
		for (let i=0; i<cols; i++) {
			list.push(i);
		}
		return list;
	}

	/**
	 * Return the number items in the column
	 * @param {Number} column Index of column
	 * @returns {Numbe} Numbe of items in the column
	 */
	getItemsInColumn(column) {
		if (!this.dashboard) { return 0; }
		return this.dashboard.items.filter(o => o.column == column).length;
	}

	/**
	 * Return the items in a specific column
	 * @param {Number} column Index of column
	 * @returns {Array<Object>} List of items
	 */
	getColumn(column) {
		if (!this.dashboard) { return []; }
		return this.dashboard.items.filter(o => o.column == column).sort(u.dynamicSort('position'));
	}

	/**
	 * Returns the width of the column, regular or web adjusted
	 * @param {Number} column Index of column
	 * @returns {Number} Width of column in pixels
	 */
	getColumnWidth(column) {
		if (!this.dashboard) { return `${s.dashboardColumnWidth}px`; }
		let width = s.dashboardColumnWidth;
		this.dashboard.items.filter(o => o.column == column).forEach(item => {
			if (item.type == 'WEB') {
				if (item.width > width) { width = item.width; }
			}
		});
		//if (width == 0) { width = 370; }
		return `${width}px`;
	}

	// #endregion

	// #region Items

	/**
	 * Get the panel at a given column/position
	 * @param {Number} column Column number
	 * @param {Number} position Position index
	 * @returns {Object} Panel object
	 */
	getItem(column, position) {
		return this.dashboard.items.find(o => o.column == column && o.position == position);
	}

	/**
	 * Add a new panel
	 * @param {String} type Type of panel: MYCALLS, EXTENSIONS...
	 * @param {Number} column Column number to add it to
	 */
	addItem(type, column) {
		const item = {
			column: column,
			position: this.getItemsInColumn(column),
			type: type,
			tag: u.randomString(64)
		};

		switch(type) {
			case 'MYCALLS':
				item.maxCalls = 2;
				item.variable = '';
				item.variableName = '';
				break;
	
			case 'QUEUE':
				item._id = null;
				item.variable = '';
				item.variableName = '';
				item.showCalls = true;
				item.showMembers = false;
				item.showSats = false;
				item.maxCalls = 5;
				item.maxMembers = 5;
				item.stats = ['UNANSWERED','ANSWERED','AVERAGEWAITTIME','LASTWAITTIME'];
				break;
	
			case 'EXTENSIONS':
				item.title = i18n.t('dashboards.newExtensionsTitle');
				item.extensions = [];
				break;

			case 'WEB':
				item.width = 390;
				item.height = 400;
				item.title = i18n.t('dashboards.newWebTitle');
				item.mode = 'STATIC';
				item.interval = 0;
				item.url = '';
				item.close = 'NEVER';
				item.ignoreLocal = false;
				break;

			case 'SWITCHVAR':
				item.title = i18n.t('dashboards.newSwitchvarTitle');
				item.variables = [];
				break;

			case 'SHORTCUTS':
				item.title = i18n.t('dashboards.newShortcutsTitle');
				item.shortcuts = [];
				break;

		}

		this.dashboard.items.push(item);
	}

	/**
	 * Delete a panel
	 * @param {Number} column Column panel is in
	 * @param {Number} position Position panel is at
	 */
	removeItem(column, position) {
		const index = this.dashboard.items.findIndex(o => o.column == column && o.position == position);
		if (index != -1) {
			this.dashboard.items.splice(index, 1);
			this.dashboard.items.forEach(item => {
				if (item.column == column && item.position > position) {
					item.position--;
				}
			});
		}

		// Check if we have an empty column but have content in subsequent columns
		const cc = this.getColumnCount();
		for (let i=0; i<cc; i++) {
			if (this.getItemsInColumn(i) == 0 && this.getItemsInColumn(i+1) > 0) {
				// Move all subsequent columns 1 column down
				this.dashboard.items.forEach(item => {
					if (item.column > i) { item.column = item.column - 1; }
				});
				i--;
			}
		}
		
	}

	// #endregion

	// #region Drag'n'drop

	/**
	 * Drag and drop completed - now move it for real
	 */
	dragDropped() {
		if (this.dragItem && this.dragTarget) {
			const oldColumn = this.dragItem.column;
			const oldPosition = this.dragItem.position;
			const newColumn = this.dragTarget.column;
			const newPosition = this.dragTarget.position == -1 ? this.getColumn(newColumn).length : this.dragTarget.position;

			// Remove it from its current position (move positions below it one up)
			this.dashboard.items.forEach(item => {
				if (item.column == oldColumn && item.position > oldPosition) {
					item.position--;
				}
			});

			// Move target and below one down
			this.dashboard.items.forEach(item => {
				if (item.column == newColumn && item.position >= newPosition) {
					item.position++;
				}
			});

			// Assign new column/position to the item
			this.dragItem.column = newColumn;
			this.dragItem.position = newPosition;

			// And clean up
			this.dragItem = null;
			this.dragTarget = null;

			// Check if we have an empty column but have content in subsequent columns
			const cc = this.getColumnCount();
			for (let i=0; i<cc; i++) {
				if (this.getItemsInColumn(i) == 0 && this.getItemsInColumn(i+1) > 0) {
					// Move all subsequent columns 1 column down
					this.dashboard.items.forEach(item => {
						if (item.column > i) { item.column = item.column - 1; }
					});
					i--;
				}
			}

		}
	}

	// #endregion

	// #region Topbar

	/** Do we have items that wish to be displayed in the topbar  */
	getUseTopbar() {
		return this.dashboards.find(o => o.topbar > 0) != null;
	}

	/** Get the list of items to display in the topbar { _id, label, index } */
	getLabelsForTopbar() {
		const labels = this.dashboards.filter(o => o.topbar > 0).map(o => { return { _id: o._id, label: o.name, index: o.topbar }; });
		labels.sort(u.dynamicSort('index'));
		return labels;
	}
}

// Singleton
export default new Dashboards();
